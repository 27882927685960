import styled from '@emotion/styled';
import { CheckCircleRounded  } from '@mui/icons-material';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { Cancel } from '../Icons';
import React from 'react';

const StyledSnackbar = styled(Snackbar)`
  @media (min-width: 600px) {
    bottom: 115px;
  }

  @media only screen and (max-width: 430px) {
    bottom: 130px;
  }

  button svg{
    color: #8B949F;
  }


  .MuiAlert-standardSuccess {
    button {
      svg {
        fill: #8b949f;
      }
    }
  }
`;

export type AlertModalProps = {
  closeSuccessAlert: (event: any) => void;
  showAlertError: boolean;
  severity?: string;
  alertPosition: string;
  color?: string;
  backgroundColor?: string;
  alertTitle?: string;
  alertMessage?: string;
};

const Component = ({
  closeSuccessAlert,
  showAlertError = false,
  severity = 'error',
  alertPosition = 'top',
  color = '#0E0E20',
  backgroundColor = '#F9DBD6',
  alertTitle = 'Error',
  alertMessage = '',
}: AlertModalProps): React.ReactElement => {
  return (
    <StyledSnackbar
      className="alert-modal"
      anchorOrigin={{
        vertical: alertPosition as 'top' | 'bottom',
        horizontal: 'center',
      }}
      open={showAlertError}
      autoHideDuration={30000}
      onClose={closeSuccessAlert}>
      <Alert
        iconMapping={{
          success: <CheckCircleRounded fontSize="inherit" />,
          error: <Cancel/>,
        }}
        onClose={closeSuccessAlert}
        severity={
          severity as 'error' | 'success' | 'warning' | 'info' | undefined
        }
        sx={{
          width: '672px',
          color: { color },
          backgroundColor: { backgroundColor },
          textAlign: 'left',
        }}>
        <AlertTitle>{alertTitle}</AlertTitle>
        {alertMessage}
      </Alert>
    </StyledSnackbar>
  );
};

export default Component;
