import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import '@mediapipe/face_detection';
import '@tensorflow/tfjs-core';
// Register WebGL backend.
import '@tensorflow/tfjs-backend-webgl';
import * as faceDetection from '@tensorflow-models/face-detection';
import {
  Face,
  MediaPipeFaceDetectorMediaPipeModelConfig,
} from '@tensorflow-models/face-detection';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  RadioGroup,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { BookIcon, UploadIcon, VideoCamera, Warning } from './components/Icons';
import { CustomizedRadio } from './components/CustomizedRadio';
import { isTablet, isMobile } from 'react-device-detect';
import { message, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { MAX_FILE_SIZE } from './constants/object';
import AlertModal from './components/Modals/AlertModal';
import { ModalHeader, PreviewHeader } from './components/Header';
import { MainCanvas } from './components/Canvas';
import { ActivePreview } from './components/ActivePreview';
import { AgreementModal } from './components/Modals';
import words from './constants/words';
import { MainFooter, PrivacyPolicy, TermsOfUse } from './components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { useErrorLogs } from './hooks/utils/useError';
import { MAX_ALLOWED_INCORRECT_COUNT } from './constants/detection';
import { restartVideo, validateVideoFile } from './utils/video';
import audioTimerLoop from './utils/audioTimerLoop';
import { scaleDownVideo } from './utils/ffmpeg';
import {
  CompanyDialog,
  LoadingButtonWrapper,
  TempVideo,
  TopBtmInfoWrapper,
  UploadPanelContainer,
  UploadPanelWrapper,
  UploadWrapper,
} from './elements';
import { LanguageSwitcher } from './components/Header';
import { Steps } from './components/Steps';
import { HowToUse } from './components/HowToUse';

const StyledDialogAction = styled(DialogActions)`
  justify-content: space-evenly;
  padding-bottom: 70px;
  padding: 0 64px 60px 64px;
  flex-direction: row-reverse;

  @media only screen and (max-width: 478px) {
    display: unset;
    text-align: center;
    padding: 0 36px 36px 36px;
  }

  #disagree-btn {
    border-radius: 8px;
    border: 3px solid var(--DS-blue-dark, #036eb8);
    background: var(--DS-white, #fff);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
    min-width: 203px;
    font-family: MsGothic;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    height: 56px;
  }

  #agree-btn {
    border-radius: 8px;
    background: var(--DS-blue-dark, #036eb8);
    min-width: 203px;
    height: 56px;
    color: #ffffff;
    color: var(--DS-white, #fff);
    text-align: center;
    font-family: MsGothic;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  @media only screen and (max-width: 478px) {
    button#agree-btn {
      margin-bottom: 10px;
      width: 100%;
    }

    button#disagree-btn {
      margin-left: 0px;
      width: 100%;
    }
  }

  @media only screen and (max-width: 430px) {
    #disagree-btn,
    #agree-btn {
      font-size: 16px;
    }
  }
`;

type MosaicSizes = 'thin' | 'normal' | 'thick';

const RadioButtonLabel = styled('span')`
  @media only screen and (max-width: 556px) {
    font-size: 12px;
  }
`;
const RadioButton = styled(FormControlLabel)`
  padding: 25px;
  @media only screen and (max-width: 556px) {
    padding: 0;
  }
`;

const WarningContainer = styled('div')`
  padding-right: 10px;
  padding-left: 13px;
  padding-bottom: 10px;

  div.warning-container p {
    color: #000;
    text-align: center;
    font-family: MsGothic;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  @media only screen and (max-width: 430px) {
    div.warning-container p {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }
`;

function App() {
  const [canvasSize, setCanvasSize] = useState({
    width: 5,
    height: 5,
  });

  const navigate = useNavigate();

  const [currentLanguage, setCurrentLanguage] = useState<'ja' | 'en' | null>(
    null,
  );

  const [isNewVideo, setIsNewVideo] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [sigPad, setSigPad] = useState<any>({});
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [imageSigData, setImageSigData] = useState('');
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false);
  const [activatePreview, setActivatePreview] = useState(false);
  const [showAlertDownload, setShowAlertDownload] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const isDownloaded = useRef(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const faceType = useRef('face');
  const colorType = useRef('mosaic');
  const filterHeight = useRef(30);
  const filterWidth = useRef(30);
  const mosaicSize = useRef<MosaicSizes>('normal');
  const possibleIncorrectFrameCount = useRef(0);

  const [stateColorType, setColorType] = useState(colorType.current);
  const [stateFaceType, setFaceType] = useState(faceType.current);
  const [stateMosaicSize, setMosaicSize] = useState(mosaicSize.current);
  const [isEdited, setIsEdited] = useState(false);

  const [disablePdfButton, setDisablePdfButton] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    title: '',
    message: '',
  });

  const frameLoopId = useRef<number | undefined>();
  const { Dragger } = Upload;
  const [showVideoInfo, setShowVideoInfo] = useState(false);
  const [fileName, setFileName] = useState('');
  const [footerMenuItem, setFooterMenuItem] = useState('');
  const frameFacePreviewRef = useRef<faceDetection.Face[]>([]);
  const frameFaceVideoRef = useRef<faceDetection.Face[]>([]);
  const [hideLoadingIcon, setHideLoadingIcon] = useState(false);
  const audioContextRef = useRef<AudioContext>();
  const audioNodeRef = useRef<MediaElementAudioSourceNode>();
  const videoStreamRef = useRef<MediaStream>();
  const destinationRef = useRef<MediaStreamAudioDestinationNode>();
  const recorderRef = useRef<MediaRecorder>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const tempVideoRef = useRef<HTMLVideoElement>(null);

  useErrorLogs();

  const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
  const detectorConfig = {
    modelType: 'full',
    runtime: 'mediapipe',
    solutionPath: process.env.PUBLIC_URL + '/model',
    maxFaces: 5,
    //https://cdn.jsdelivr.net/npm/@mediapipe/face_detection - online
  };

  const start = async (
    uploadedFile?: any,
    isReuse?: boolean,
    isCapture = false,
  ) => {
    const video = videoRef.current;
    const tempVideo = tempVideoRef.current;
    if (!video || !tempVideo) return;

    setIsLoading(true);

    if (uploadedFile?.name) {
      const strSplit = uploadedFile?.name.split('.');
      setFileName(strSplit[0]);
    } else {
      setFileName('capturedVideo');
    }
    setActivatePreview(true);
    setIsNewVideo(false);

    const canvas = document.getElementById('canvas') as HTMLCanvasElement;

    let urlBlob = '';
    const fileItem = document.getElementById('fileItemCapture') as any;
    const file = isCapture ? fileItem?.files[0] : uploadedFile;

    if (!isReuse) {
      urlBlob = URL.createObjectURL(file);

      tempVideoRef.current.src = urlBlob;
      tempVideoRef.current.load();
    }

    const loadMetadata = new Promise<string>((resolve, reject) => {
      tempVideo.addEventListener('loadedmetadata', async () => {
        const w = tempVideo.videoWidth;
        const h = tempVideo.videoHeight;
        if (w > 1920 && h > 1080) {
          try {
            const newVidSrc = await scaleDownVideo({
              uploadedFile: file,
            });
            if (!newVidSrc) {
              resolve(urlBlob);
              return;
            }
            urlBlob = newVidSrc;
            resolve(urlBlob);
          } catch (error) {
            reject(error);
          }
        } else {
          resolve(urlBlob);
        }
      });
    });

    const finalUrl = await loadMetadata;

    video.src = finalUrl;
    video.muted = true;
    video.load();

    video.addEventListener('loadedmetadata', function () {
      setCanvasSize({
        width: video.videoWidth,
        height: video.videoHeight,
      });
    });

    video.onloadeddata = async function () {
      const [detector] = await Promise.all([
        faceDetection.createDetector(
          model,
          detectorConfig as MediaPipeFaceDetectorMediaPipeModelConfig,
        ),
        restartVideo(),
      ]);

      if (!detector) {
        // TODO: unlikely behaviour but still possible
        console.error('TODO: detector did not load');
        return;
      }

      if (!destinationRef.current) {
        videoStreamRef.current = canvas.captureStream(25);
        setupAudioContext();
        prepareFirstUploadPreview(video.src, detector);
      } else {
        if (frameLoopId.current) {
          cancelAnimationFrame(frameLoopId.current);
        }
      }
      startFrameLoop(video, detector);
    };
  };

  const prepareFirstUploadPreview = async (
    videoUrl: string,
    detector: faceDetection.FaceDetector,
  ) => {
    const canvasVideo: HTMLElement | null = document.getElementById('canvas');
    const domainUrl = window.location.origin + '/';

    if (!canvasVideo) {
      // NOTE: will only happen if canvas tag has been removed from code.
      console.error(`canvas element not found`);
      return;
    }
    canvasVideo.style.display = 'none';
    const ffmpeg = createFFmpeg({
      log: true,
      corePath: domainUrl + 'core/ffmpeg-core.js',
      wasmPath: domainUrl + 'core/ffmpeg-core.wasm',
      workerPath: domainUrl + 'core/ffmpeg-core.worker.js',
    });
    await ffmpeg.load();
    const outputPath = 'image-%03d.jpg';
    ffmpeg.FS('writeFile', 'input.mp4', await fetchFile(videoUrl));
    await ffmpeg.run(
      '-i',
      'input.mp4',
      '-ss',
      '00:00:00.000',
      '-vframes',
      '1',
      outputPath,
    );

    const files = ffmpeg.FS('readdir', '/');
    const imagePaths = files.filter((file) => file.endsWith('.jpg'));
    const imageUrls = imagePaths.map((path) =>
      URL.createObjectURL(new Blob([ffmpeg.FS('readFile', path)])),
    );

    ffmpeg.FS('unlink', 'input.mp4');
    ffmpeg.exit();

    const canvas: HTMLCanvasElement | null = document.getElementById(
      'imgCanvas',
    ) as HTMLCanvasElement;
    const img: HTMLImageElement | null = document.getElementById(
      'imageTag',
    ) as HTMLImageElement;
    const ctx = canvas.getContext('2d');
    if (!canvas || !img || !ctx) {
      // NOTE: will only happen if canvas, img tag has been removed from code.
      console.error('canvas, img or ctx not found');
      return;
    }
    const imageURL = imageUrls.slice(0)[0];
    img.src = imageURL;

    img.onload = async () => {
      if (img.complete) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.beginPath();
        ctx.drawImage(img, 0, 0);

        const estimationConfig = { flipHorizontal: false };
        const faces = await detector.estimateFaces(img, estimationConfig);
        setIsLoading(false);
        frameFacePreviewRef.current = faces;
        drawPreview(canvas, faces, 'imageTag');
      }
    };
  };

  const startFrameLoop = (
    video: HTMLVideoElement,
    detector: faceDetection.FaceDetector,
  ) => {
    const canvas = document.getElementById('canvas') as any;
    const ctx = canvas.getContext('2d');

    const drawPerFrame = (
      detections: Face[],
      offscreenCanvas: HTMLCanvasElement,
      offscreenCtx: any,
    ) => {
      ctx.clearRect(0, 0, ctx.width, ctx.height);
      ctx.beginPath();
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      if (detections.length > 0) {
        offscreenCanvas.width = ctx.canvas.width;
        offscreenCanvas.height = ctx.canvas.height;

        const imgHeightRatio = ctx.canvas.height / ctx.canvas.width;
        const imgWidthRatio = ctx.canvas.width / ctx.canvas.height;
        const sizeDelta = (ctx.canvas.height + ctx.canvas.width) / (1280 + 720);

        const newImgHeight = Math.round(ctx.canvas.width * imgHeightRatio);
        const newImgWidth = Math.round(newImgHeight * imgWidthRatio);

        const blockSize = newImgHeight - newImgWidth;
        const size = (blockSize > 500 ? 5 : 10) / 100;
        const w = Math.ceil(ctx.canvas.width * size);
        const h = Math.ceil(ctx.canvas.height * size);

        const colorFill =
          colorType.current === 'black'
            ? 'rgb(18, 17, 17)'
            : colorType.current === 'gray'
              ? 'rgb(158, 152, 152)'
              : colorType.current === 'dark_skin'
                ? 'rgb(204, 183, 159)'
                : 'rgb(80,65,53)';

        if (faceType.current === 'eye') {
          detections.forEach((face) => {
            const keypoints = face.keypoints
              .filter(
                (keypoint) =>
                  keypoint.name === 'leftEye' || keypoint.name === 'rightEye',
              )
              .map((keypoint) => [keypoint.x, keypoint.y, keypoint.name]);

            const rightEye = keypoints[0];
            const leftEye = keypoints[1];

            const width = Math.abs(Number(rightEye[0]) - Number(leftEye[0]));
            const height = Math.abs(Number(rightEye[1]) - Number(leftEye[1]));
            const xMin = Math.min(Number(rightEye[0]), Number(leftEye[0]));
            const yMin = Math.min(Number(rightEye[1]), Number(leftEye[1]));
            const increaseWidth =
              (45 + Number(filterWidth.current)) * sizeDelta;
            const increaseHeight =
              (30 + Number(filterHeight.current)) * sizeDelta;

            if (colorType.current === 'mosaic') {
              offscreenCtx.imageSmoothingEnabled = false;

              offscreenCtx.drawImage(video, 0, 0, w, h);
              offscreenCtx.drawImage(
                offscreenCanvas,
                0,
                0,
                w,
                h,
                0,
                0,
                ctx.canvas.width,
                ctx.canvas.height,
              );

              ctx.drawImage(
                offscreenCanvas,
                xMin - increaseWidth / 2,
                yMin - increaseHeight / 2,
                width + increaseWidth,
                height + increaseHeight,
                xMin - increaseWidth / 2,
                yMin - increaseHeight / 2,
                width + increaseWidth,
                height + increaseHeight,
              );
            } else if (
              colorType.current === 'blur' &&
              (false || offscreenCtx)
            ) {
              offscreenCtx.drawImage(video, 0, 0, w, h);
              offscreenCtx.drawImage(
                offscreenCanvas,
                0,
                0,
                w,
                h,
                0,
                0,
                ctx.canvas.width,
                ctx.canvas.height,
              );

              ctx.drawImage(
                offscreenCanvas,
                xMin - increaseWidth / 2,
                yMin - increaseHeight / 2,
                width + increaseWidth,
                height + increaseHeight,
                xMin - increaseWidth / 2,
                yMin - increaseHeight / 2,
                width + increaseWidth,
                height + increaseHeight,
              );
            } else {
              ctx.fillStyle = colorFill;
              ctx.fillRect(
                xMin - increaseWidth / 2,
                yMin - increaseHeight / 2,
                width + increaseWidth,
                height + increaseHeight,
              );
            }
          });
        }

        if (faceType.current === 'face') {
          detections.forEach((data) => {
            const width = Number(data.box.width);
            const height = Number(data.box.height);
            const increaseWidth =
              (20 + Number(filterWidth.current)) * sizeDelta;
            const increaseHeight =
              (20 + Number(filterHeight.current)) * sizeDelta;

            if (data.box) {
              if (colorType.current === 'mosaic') {
                offscreenCtx.imageSmoothingEnabled = false;
                offscreenCtx.drawImage(video, 0, 0, w, h);
                offscreenCtx.drawImage(
                  offscreenCanvas,
                  0,
                  0,
                  w,
                  h,
                  0,
                  0,
                  ctx.canvas.width,
                  ctx.canvas.height,
                );
                ctx.drawImage(
                  offscreenCanvas,
                  data.box.xMin - increaseWidth / 2,
                  data.box.yMin - increaseHeight / 2,
                  width + increaseWidth,
                  height + increaseHeight,
                  data.box.xMin - increaseWidth / 2,
                  data.box.yMin - increaseHeight / 2,
                  data.box.width + increaseWidth,
                  data.box.height + increaseHeight,
                );
              } else if (
                colorType.current === 'blur' &&
                (false || offscreenCtx)
              ) {
                offscreenCtx.drawImage(video, 0, 0, w, h);
                offscreenCtx.drawImage(
                  offscreenCanvas,
                  0,
                  0,
                  w,
                  h,
                  0,
                  0,
                  ctx.canvas.width,
                  ctx.canvas.height,
                );

                ctx.drawImage(
                  offscreenCanvas,
                  data.box.xMin - increaseWidth / 2,
                  data.box.yMin - increaseHeight / 2,
                  width + increaseWidth,
                  height + increaseHeight,
                  data.box.xMin - increaseWidth / 2,
                  data.box.yMin - increaseHeight / 2,
                  data.box.width + increaseWidth,
                  data.box.height + increaseHeight,
                );
              } else {
                ctx.fillStyle = colorFill;
                ctx.fillRect(
                  data.box.xMin - increaseWidth / 2,
                  data.box.yMin - increaseHeight / 2,
                  width + increaseWidth,
                  height + increaseHeight,
                );
              }
            }
          });
        }
        ctx.stroke();
      }
    };
    const alreadyChecked = false;

    const offscreenCanvas = document.createElement('canvas') as any;
    const offscreenCtx = offscreenCanvas.getContext('2d', {
      willReadFrequently: true,
    });

    const renderLoop = async () => {
      const estimationConfig = { flipHorizontal: false };
      const faces = await detector.estimateFaces(video, estimationConfig);
      const videoCurrentTime = video.currentTime;
      const shouldProcess =
        videoCurrentTime < video.duration && isDownloaded.current === false;

      setIsProcessing(shouldProcess);

      if (shouldProcess) {
        const previousFrames = frameFaceVideoRef.current;
        const newFrames = faces;

        if (
          newFrames.length >= previousFrames.length ||
          possibleIncorrectFrameCount.current > MAX_ALLOWED_INCORRECT_COUNT
        ) {
          frameFaceVideoRef.current = faces;
          possibleIncorrectFrameCount.current = 0;
        } else {
          possibleIncorrectFrameCount.current += 1;
        }

        if (Math.floor(videoCurrentTime) > 0) {
          drawPerFrame(
            frameFaceVideoRef.current,
            offscreenCanvas,
            offscreenCtx,
          );
        } else {
          drawPerFrame(faces, offscreenCanvas, offscreenCtx);
          frameFaceVideoRef.current = faces;
        }

        if (alreadyChecked) {
          setShowVideoInfo(false);
        }
      }

      // TODO please put in a better and more specific timing
      if (faces && faces.length > 0) {
        localStorage.setItem('colorType', colorType.current);
        localStorage.setItem('faceType', faceType.current);
        localStorage.setItem('mosaicSize', mosaicSize.current);
      }

      if (isMobile) {
        frameLoopId.current = requestAnimationFrame(() => {
          // Using 30ms delay to control the frame rate roughly to 33 FPS (1000/30 ≈ 33.33)
          // feel free to reduce to 16 but may hurt performance for slower machines
          setTimeout(renderLoop, 30);
        });
      }
    };

    if (isMobile) {
      renderLoop();
    } else {
      audioTimerLoop(() => {
        renderLoop();
      }, 1000 / 30);
    }
  };

  const drawPreview = (canvas: any, detections: Face[], tagName = 'video') => {
    const previewTag = document.getElementById(tagName) as any;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, ctx.width, ctx.height);
    ctx.beginPath();
    ctx.drawImage(
      previewTag,
      0,
      0,
      previewTag.videoWidth,
      previewTag.videoHeight,
    );

    if (detections.length > 0) {
      const offscreenCanvas = document.createElement('canvas') as any;
      offscreenCanvas.width = ctx.canvas.width;
      offscreenCanvas.height = ctx.canvas.height;
      const offscreenCtx = offscreenCanvas.getContext('2d', {
        willReadFrequently: true,
      });

      const imgHeightRatio = ctx.canvas.height / ctx.canvas.width;
      const imgWidthRatio = ctx.canvas.width / ctx.canvas.height;
      const sizeDelta = (ctx.canvas.height + ctx.canvas.width) / (1280 + 720);

      const newImgHeight = Math.round(ctx.canvas.width * imgHeightRatio);
      const newImgWidth = Math.round(newImgHeight * imgWidthRatio);

      const blockSize = newImgHeight - newImgWidth;
      const size = (blockSize > 500 ? 5 : 10) / 100;
      const w = Math.ceil(ctx.canvas.width * size);
      const h = Math.ceil(ctx.canvas.height * size);

      const colorFill =
        colorType.current === 'black'
          ? 'rgb(18, 17, 17)'
          : colorType.current === 'gray'
            ? 'rgb(158, 152, 152)'
            : colorType.current === 'dark_skin'
              ? 'rgb(204, 183, 159)'
              : 'rgb(80,65,53)';

      if (faceType.current === 'eye') {
        detections.forEach((face) => {
          const keypoints = face.keypoints
            .filter(
              (keypoint) =>
                keypoint.name === 'leftEye' || keypoint.name === 'rightEye',
            )
            .map((keypoint) => [keypoint.x, keypoint.y, keypoint.name]);

          const rightEye = keypoints[0];
          const leftEye = keypoints[1];

          const width = Math.abs(Number(rightEye[0]) - Number(leftEye[0]));
          const height = Math.abs(Number(rightEye[1]) - Number(leftEye[1]));
          const xMin = Math.min(Number(rightEye[0]), Number(leftEye[0]));
          const yMin = Math.min(Number(rightEye[1]), Number(leftEye[1]));
          const increaseWidth = (45 + Number(filterWidth.current)) * sizeDelta;
          const increaseHeight =
            (30 + Number(filterHeight.current)) * sizeDelta;

          if (colorType.current === 'mosaic' && offscreenCtx) {
            offscreenCtx.mozImageSmoothingEnabled = false;
            offscreenCtx.webkitImageSmoothingEnabled = false;
            offscreenCtx.imageSmoothingEnabled = false;

            offscreenCtx.drawImage(previewTag, 0, 0, w, h);
            offscreenCtx.drawImage(
              offscreenCanvas,
              0,
              0,
              w,
              h,
              0,
              0,
              ctx.canvas.width,
              ctx.canvas.height,
            );

            ctx.drawImage(
              offscreenCanvas,
              xMin - increaseWidth / 2,
              yMin - increaseHeight / 2,
              width + increaseWidth,
              height + increaseHeight,
              xMin - increaseWidth / 2,
              yMin - increaseHeight / 2,
              width + increaseWidth,
              height + increaseHeight,
            );
          } else if (colorType.current === 'blur' && offscreenCtx) {
            offscreenCtx.drawImage(previewTag, 0, 0, w, h);
            offscreenCtx.drawImage(
              offscreenCanvas,
              0,
              0,
              w,
              h,
              0,
              0,
              ctx.canvas.width,
              ctx.canvas.height,
            );

            ctx.drawImage(
              offscreenCanvas,
              xMin - increaseWidth / 2,
              yMin - increaseHeight / 2,
              width + increaseWidth,
              height + increaseHeight,
              xMin - increaseWidth / 2,
              yMin - increaseHeight / 2,
              width + increaseWidth,
              height + increaseHeight,
            );
          } else {
            ctx.fillStyle = colorFill;
            ctx.fillRect(
              xMin - increaseWidth / 2,
              yMin - increaseHeight / 2,
              width + increaseWidth,
              height + increaseHeight,
            );
          }
        });
      }

      if (faceType.current === 'face') {
        detections.forEach((data) => {
          const width = Number(data.box.width);
          const height = Number(data.box.height);
          const increaseWidth = (20 + Number(filterWidth.current)) * sizeDelta;
          const increaseHeight =
            (20 + Number(filterHeight.current)) * sizeDelta;

          if (data.box) {
            if (colorType.current === 'mosaic' && offscreenCtx) {
              offscreenCtx.mozImageSmoothingEnabled = false;
              offscreenCtx.webkitImageSmoothingEnabled = false;
              offscreenCtx.imageSmoothingEnabled = false;

              offscreenCtx.drawImage(previewTag, 0, 0, w, h);
              offscreenCtx.drawImage(
                offscreenCanvas,
                0,
                0,
                w,
                h,
                0,
                0,
                ctx.canvas.width,
                ctx.canvas.height,
              );

              ctx.drawImage(
                offscreenCanvas,
                data.box.xMin - increaseWidth / 2,
                data.box.yMin - increaseHeight / 2,
                width + increaseWidth,
                height + increaseHeight,
                data.box.xMin - increaseWidth / 2,
                data.box.yMin - increaseHeight / 2,
                data.box.width + increaseWidth,
                data.box.height + increaseHeight,
              );
            } else if (
              colorType.current === 'blur' &&
              (false || offscreenCtx)
            ) {
              offscreenCtx.drawImage(previewTag, 0, 0, w, h);
              offscreenCtx.drawImage(
                offscreenCanvas,
                0,
                0,
                w,
                h,
                0,
                0,
                ctx.canvas.width,
                ctx.canvas.height,
              );

              ctx.drawImage(
                offscreenCanvas,
                data.box.xMin - increaseWidth / 2,
                data.box.yMin - increaseHeight / 2,
                width + increaseWidth,
                height + increaseHeight,
                data.box.xMin - increaseWidth / 2,
                data.box.yMin - increaseHeight / 2,
                data.box.width + increaseWidth,
                data.box.height + increaseHeight,
              );
            } else {
              ctx.fillStyle = colorFill;
              ctx.fillRect(
                data.box.xMin - increaseWidth / 2,
                data.box.yMin - increaseHeight / 2,
                width + increaseWidth,
                height + increaseHeight,
              );
            }
          }
        });
      }
      ctx.stroke();
    }
  };

  const uploadVideo = () => {
    if (isDownloaded.current) {
      localStorage.setItem('boxWidth', '0');
      localStorage.setItem('boxHeight', '0');
      window.location.reload();
      setIsNewVideo(!isNewVideo);
    } else {
      setOpenDialog(true);
    }
  };

  const switchCanvas = (switchCanvas: boolean) => {
    if (!switchCanvas) {
      const canvasImg = document.getElementById('imgCanvas') as any;
      const canvas = document.getElementById('canvas') as any;
      canvasImg.style.display = 'none';
      canvas.style.display = 'unset';
    } else {
      const canvasImg = document.getElementById('imgCanvas') as any;
      const canvas = document.getElementById('canvas') as any;
      canvas.style.display = 'none';
      canvasImg.style.display = 'unset';
    }
  };

  const setupAudioContext = () => {
    const audioNode = audioNodeRef.current;
    const videoStream = videoStreamRef.current;
    let destination = destinationRef.current;

    if (!videoStream) return;

    const video = document.getElementsByTagName('video')[0];

    if (!audioNode) {
      const audioContext = new AudioContext();
      const sourceNode = audioContext.createMediaElementSource(video);
      destination = audioContext.createMediaStreamDestination();
      sourceNode.connect(destination);
      sourceNode.connect(audioContext.destination);

      audioContextRef.current = audioContext;
      audioNodeRef.current = sourceNode;
    } else {
      if (audioContextRef.current) {
        const audioContext = audioContextRef.current;
        destination = audioContext.createMediaStreamDestination();
        audioNode.connect(destination);
        audioNode.connect(audioContext.destination);
      }
    }

    destinationRef.current = destination;

    if (!destination) return;

    const stream = new MediaStream([
      ...videoStream.getVideoTracks(),
      ...destination.stream.getAudioTracks(),
    ]);

    recorderRef.current = new MediaRecorder(stream, {
      videoBitsPerSecond: 15000000,
      audioBitsPerSecond: 128000,
    });
  };

  const playVideo = async () => {
    const video = document.getElementsByTagName('video')[0];
    video.muted = false;

    setIsLoading(true);
    switchCanvas(false);
    setHideLoadingIcon(true);

    const chunks: any[] = [];
    const saveChunks = (e: any) => {
      e.data.size && chunks.push(e.data);
    };

    const destination = destinationRef.current;
    const videoStream = videoStreamRef.current;
    const recorder = recorderRef.current;
    const audioContext = audioContextRef.current;

    if (!videoStream || !recorder || !audioContext) return;

    if (!destination) {
      // TODO: show error message if this actually happens in real life scenario
      console.error('stream/destination not found');
      return;
    }

    if (audioContext.state !== 'running') {
      await audioContext.resume();
    }

    await video.play();
    recorder.start();
    video.onpause = async () => {
      if (video.currentTime < video.duration) {
        setIsLoading(false);
        switchCanvas(true);
        setIsEdited(true);
        recorder.onstop = null;
        recorder.stop();
        await restartVideo();
      }
    };

    recorder.ondataavailable = (event) => {
      saveChunks(event);
    };

    recorder.onstop = () => {
      if (chunks.length) {
        const blob = new Blob(chunks, { type: chunks[0].type });
        const vidURL = URL.createObjectURL(blob);

        setVideoUrl(vidURL);
      }

      setIsLoading(false);
    };

    video.addEventListener('ended', function () {
      recorder.stop();
    });
  };

  // TODO: to be used in the future. current agreement modal task is postponed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOpen = () => {
    setIsAgreementModalOpen(true);
  };
  const handleAgreementClose = () => {
    setIsAgreementModalOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseCompanyDialog = () => {
    const getCurrentLanguage = localStorage.getItem('currentLanguage');
    setOpenCompanyDialog(false);
    setFooterMenuItem('');
    if (getCurrentLanguage === 'en') {
      navigate('/en/');
    } else {
      navigate('');
    }
  };

  const handleAgreeDialog = () => {
    document.getElementsByTagName('body')[0].style.display = 'none';
    if (frameLoopId.current) {
      cancelAnimationFrame(frameLoopId.current);
    }
    setOpenDialog(false);
    window.location.reload();
  };

  const closeSuccessAlert = () => {
    setShowAlertDownload(false);
    setShowAlertError(false);
  };

  const handleSaveDownload = () => {
    if (userName !== '') {
      const convertedSigData = sigPad.getTrimmedCanvas().toDataURL('image/png');
      setImageSigData(convertedSigData);
      setDisablePdfButton(false);
    } else {
      setIsError(true);
    }
  };

  const handleClearSignature = () => {
    setDisablePdfButton(true);
    sigPad.clear();
  };

  const managePreviewCanvases = async () => {
    const img = document.getElementById('imageTag') as any;
    const canvas = document.getElementById('imgCanvas') as any;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, img.videoWidth, img.videoHeight);
    ctx.beginPath();
    ctx.drawImage(img, 0, 0);
    if (!showVideoInfo) {
      drawPreview(canvas, frameFacePreviewRef.current, 'imageTag');
    }

    switchCanvas(true);
    await restartVideo();
  };

  const setMosaicFilterDimensions = (size: MosaicSizes) => {
    switch (size) {
      case 'thin':
        filterWidth.current = 0;
        filterHeight.current = 0;
        break;
      case 'normal':
        filterWidth.current = 30;
        filterHeight.current = 30;
        break;
      case 'thick':
        filterWidth.current = 70;
        filterHeight.current = 70;
        break;
    }
  };

  const handleChangeSettings = (
    event,
    type: 'face' | 'color' | 'mosaic-size',
  ) => {
    const value = event.target.value;
    setIsEdited(true);
    isDownloaded.current = false;
    switch (type) {
      case 'face':
        faceType.current = value;
        setFaceType(value);
        break;
      case 'color':
        colorType.current = value;
        setColorType(value);
        break;
      case 'mosaic-size':
        mosaicSize.current = value;
        setMosaicSize(value);
        setMosaicFilterDimensions(value);
        break;
    }
    managePreviewCanvases();
  };

  useEffect(() => {
    if (currentLanguage === null) return;
    if (
      window.location.href.indexOf('/en/privacy') !== -1 ||
      window.location.href.indexOf('/privacy') !== -1
    ) {
      onClickFooterItems('privacyPolicy');
      return;
    }

    if (
      window.location.href.indexOf('/en/terms') !== -1 ||
      window.location.href.indexOf('/terms') !== -1
    ) {
      onClickFooterItems('termsOfUse');
      return;
    }

    if (
      window.location.href.indexOf('/en/guide') !== -1 ||
      window.location.href.indexOf('/guide') !== -1
    ) {
      onClickFooterItems('howToUse');
      return;
    }

    if (currentLanguage === 'en') {
      navigate('/en/');
      return;
    }

    navigate('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const location = useLocation();
  useEffect(() => {
    if (currentLanguage === null) return;

    if (
      currentLanguage === 'en' &&
      (location.pathname.indexOf('/en/') !== -1 ||
        location.pathname.indexOf('/en') !== -1)
    )
      return;

    if (
      currentLanguage === 'ja' &&
      (location.pathname.indexOf('/en/') === -1 ||
        location.pathname.indexOf('/en') === -1)
    )
      return;

    window.location.reload();
  }, [location, currentLanguage]);

  useEffect(() => {
    const getDataBoxWidth = localStorage.getItem('boxWidth');
    const getDataBoxHeight = localStorage.getItem('boxHeight');
    const getDataColorType = localStorage.getItem('colorType');
    const getDataFaceType = localStorage.getItem('faceType');
    const getDataMosaicSize = localStorage.getItem('mosaicSize');

    if (
      window.location.href.indexOf('/en/') !== -1 ||
      window.location.href.indexOf('/en') !== -1
    ) {
      setCurrentLanguage('en');
      localStorage.setItem('currentLanguage', 'en');
    } else {
      setCurrentLanguage('ja');
      localStorage.setItem('currentLanguage', 'ja');
    }

    if (getDataBoxWidth) {
      const boxWidth = Math.round(+getDataBoxWidth);
      filterWidth.current = boxWidth;
    }

    if (getDataBoxHeight) {
      const boxHeight = Math.round(+getDataBoxHeight);
      filterHeight.current = boxHeight;
    }

    if (getDataColorType) {
      colorType.current = getDataColorType;
      setColorType(getDataColorType);
    }

    if (getDataFaceType) {
      faceType.current = getDataFaceType;
      setFaceType(getDataFaceType);
    }

    if (
      getDataMosaicSize &&
      (getDataMosaicSize === 'thin' ||
        getDataMosaicSize === 'normal' ||
        getDataMosaicSize === 'thick')
    ) {
      mosaicSize.current = getDataMosaicSize;
      setMosaicSize(getDataMosaicSize);
      setMosaicFilterDimensions(getDataMosaicSize);
    }

    const onPageHidden = async (evt: PageTransitionEvent) => {
      const video = videoRef.current;
      if (!video || !isDownloaded.current || !evt.persisted) {
        return;
      }
      video.load();
      isDownloaded.current = false;
    };

    window.addEventListener('pagehide', onPageHidden);

    return () => {
      window.removeEventListener('pagehide', onPageHidden);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: 'video/mp4,video/quicktime',
    beforeUpload: () => {
      return false;
    },
    onChange: async (info) => {
      const { status } = info.file;
      if (status !== 'uploading') {
        if (info.file instanceof File) {
          const isValid = await validateVideoFile(info.file);
          if (!isValid) {
            setSnackbar({
              open: true,
              title: words.errorTitle,
              message: `${info.file.name} ${words.error.fileFormatError}`,
            });
            return;
          }
        }

        if (info && info.file.size && info.file.size <= MAX_FILE_SIZE) {
          start(info.file);
        } else {
          setShowAlertError(true);
        }
      }

      if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop() {},
  };

  const onLangSwitch = (lang: string) => {
    if (lang === 'en') {
      localStorage.setItem('currentLanguage', 'en');
      setCurrentLanguage('en');
    }

    if (lang === 'ja') {
      localStorage.setItem('currentLanguage', 'ja');
      setCurrentLanguage('ja');
    }
  };

  const contentTarget = (
    <RadioGroup
      row
      sx={{ gap: '2em' }}
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      className="radio-group-btn"
      value={stateFaceType}
      onChange={(event) => handleChangeSettings(event, 'face')}>
      <RadioButton
        value="eye"
        control={<CustomizedRadio />}
        label={<RadioButtonLabel>{words.eye}</RadioButtonLabel>}
        disabled={isLoading}
      />
      <RadioButton
        value="face"
        control={<CustomizedRadio />}
        label={<RadioButtonLabel>{words.face}</RadioButtonLabel>}
        disabled={isLoading}
      />
    </RadioGroup>
  );

  const contentType = (
    <RadioGroup
      row
      sx={{ gap: '2em' }}
      name="row-radio-buttons-group"
      className="radio-group-btn"
      value={stateColorType}
      onChange={(event) => handleChangeSettings(event, 'color')}>
      <RadioButton
        value="mosaic"
        control={<CustomizedRadio />}
        label={<RadioButtonLabel>{words.mosaic}</RadioButtonLabel>}
        disabled={isLoading}
      />
      <RadioButton
        value="blur"
        control={<CustomizedRadio />}
        label={<RadioButtonLabel>{words.blur}</RadioButtonLabel>}
        disabled={isLoading}
      />
      <RadioButton
        value="black"
        control={<CustomizedRadio />}
        label={<RadioButtonLabel>{words.black}</RadioButtonLabel>}
        disabled={isLoading}
      />
      <RadioButton
        value="dark_skin"
        control={<CustomizedRadio />}
        label={<RadioButtonLabel>{words.dark_skin}</RadioButtonLabel>}
        disabled={isLoading}
      />
    </RadioGroup>
  );

  const contentMosaic = (
    <RadioGroup
      row
      sx={{ gap: '2em' }}
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      className="radio-group-btn"
      value={stateMosaicSize}
      onChange={(event) => handleChangeSettings(event, 'mosaic-size')}>
      <RadioButton
        value="thin"
        control={<CustomizedRadio />}
        label={<RadioButtonLabel>{words.thin}</RadioButtonLabel>}
        disabled={isLoading}
      />
      <RadioButton
        value="normal"
        control={<CustomizedRadio />}
        label={<RadioButtonLabel>{words.normal}</RadioButtonLabel>}
        disabled={isLoading}
      />
      <RadioButton
        value="thick"
        control={<CustomizedRadio />}
        label={<RadioButtonLabel>{words.thick}</RadioButtonLabel>}
        disabled={isLoading}
      />
    </RadioGroup>
  );

  const downloadVideo = () => {
    isDownloaded.current = true;
    setIsEdited(false);
    if (!isMobile) {
      setShowAlertDownload(true);
    }
  };

  const handleVideoSettings = () => {
    setIsEdited(false);
    playVideo();
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const onClickFooterItems = (item: string) => {
    const getCurrentLanguage = localStorage.getItem('currentLanguage');
    setTimeout(() => {
      const myElement = document.getElementById('alert-company-content');
      myElement?.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

    setFooterMenuItem(item);

    if (
      item === 'termsOfUse' ||
      item === 'privacyPolicy' ||
      item === 'howToUse'
    ) {
      if (item === 'privacyPolicy') {
        getCurrentLanguage === 'en'
          ? navigate(`/en/privacy`)
          : navigate(`/privacy`);
      } else if (item === 'termsOfUse') {
        getCurrentLanguage === 'en'
          ? navigate(`/en/terms`)
          : navigate(`/terms`);
      } else if (item === 'howToUse') {
        getCurrentLanguage === 'en'
          ? navigate(`/en/guide`)
          : navigate(`/guide`);
      } else {
        navigate(``);
      }

      setOpenCompanyDialog(true);
    } else {
      setOpenCompanyDialog(false);
    }

    if (item === 'company') {
      openInNewTab('https://www.orgo.co.jp/');
      if (getCurrentLanguage === 'en') {
        navigate('/en/');
      } else {
        navigate('');
      }
    }
  };

  useEffect(() => {
    // NOTE: this is to prevent the page from scrolling to the last position when in Safari: https://developer.mozilla.org/en-US/docs/Web/API/History/scrollRestoration
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    window.onscroll = () => {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    };

    setTimeout(() => {
      window.onscroll = null;
    }, 5000);
  }, []);

  return (
    <div className="App">
      <div
        style={{
          background: activatePreview ? '#ffffff' : '#E9ECF3',
          flex: 1,
          height: '100%',
          maxHeight: '100%',
        }}>
        {activatePreview && (
          <PreviewHeader
            downloadVideo={downloadVideo}
            uploadVideo={uploadVideo}
            videoUrl={videoUrl}
            isLoading={isLoading}
          />
        )}
        <div>
          <MainCanvas
            activatePreview={activatePreview}
            canvasSizeHeight={canvasSize.height}
            canvasSizeWidth={canvasSize.width}
            showVideoInfo={showVideoInfo}
            isMobile={isMobile}
            isTablet={isTablet}
            isLoading={isLoading}
            hideLoadingIcon={hideLoadingIcon}
          />
          {!activatePreview ? (
            <div>
              <AlertModal
                alertPosition="bottom"
                closeSuccessAlert={closeSuccessAlert}
                showAlertError={showAlertError}
                severity="error"
                alertTitle={words.errorTitle}
                alertMessage={words.fileSizeLimitError}></AlertModal>
              {currentLanguage && (
                <LanguageSwitcher
                  currentLanguage={currentLanguage}
                  onLangSwitch={onLangSwitch}
                  onLogoClick={handleCloseCompanyDialog}
                />
              )}
              <UploadWrapper className="upload-wrapper">
                <Steps />

                <UploadPanelContainer className="upload_panel_container">
                  <UploadPanelWrapper>
                    <Dragger {...props}>
                      <UploadIcon />
                      <p className="upload_title">
                        {words.uploadLabel}
                        <span>{words.uploadLimit}</span>
                      </p>
                    </Dragger>
                  </UploadPanelWrapper>
                </UploadPanelContainer>

                <LoadingButtonWrapper>
                  <LoadingButton
                    fullWidth
                    size="large"
                    component="label"
                    loading={isLoading}
                    loadingPosition="start"
                    variant="contained"
                    startIcon={<VideoCamera />}>
                    <span>{words.recordAVideo}</span>
                    <input
                      style={{
                        visibility: 'hidden',
                        position: 'absolute',
                      }}
                      id="fileItemCapture"
                      type="file"
                      accept="video/*"
                      onChange={() => {
                        start('', false, true);
                      }}
                      capture="environment"
                    />
                  </LoadingButton>
                </LoadingButtonWrapper>
              </UploadWrapper>
              <TopBtmInfoWrapper className="top_btm_info">
                <div className={`top_btm_info_box ${currentLanguage}`}>
                  <div
                    className={
                      footerMenuItem === 'howToUse'
                        ? 'how_to_use active'
                        : 'how_to_use'
                    }
                    onClick={() => onClickFooterItems('howToUse')}>
                    <BookIcon />
                    {words.howToUse}
                  </div>
                  <p className="pc_tab">
                    {words.topBtmInfo.text1}
                    <span>{words.topBtmInfo.text2}</span>
                  </p>
                  <p className="mobile">
                    {words.topBtmInfo.text1}
                    {words.topBtmInfo.text2}
                  </p>
                </div>
              </TopBtmInfoWrapper>
              <MainFooter
                onClickItem={onClickFooterItems}
                menuItem={footerMenuItem}
              />
            </div>
          ) : (
            <ActivePreview
              isProcessing={isProcessing}
              submitVideo={handleVideoSettings}
              downloadVideo={downloadVideo}
              isLoading={isLoading}
              closeSuccessAlert={closeSuccessAlert}
              videoUrl={videoUrl}
              contentTarget={contentTarget}
              contentType={contentType}
              contentMosaic={contentMosaic}
              showAlert={showAlertDownload}
              faceTarget={faceType.current}
              faceType={colorType.current}
              mosaicSize={mosaicSize.current}
              isEdited={isEdited}
              filename={fileName}
            />
          )}
        </div>

        <AgreementModal
          open={isAgreementModalOpen}
          handleClose={handleAgreementClose}
          isError={isError}
          userName={userName}
          setUserName={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUserName(event.target.value);
          }}
          handleClearSignature={handleClearSignature}
          handleSaveDownload={handleSaveDownload}
          setSigPad={(sigPad: any) => {
            setSigPad(sigPad);
          }}
          imageSigData={imageSigData}
          disablePdfButton={disablePdfButton}
        />

        <video
          ref={videoRef}
          preload="metadata"
          style={{
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100px',
            height: '100px',
            opacity: 0,
            zIndex: -999,
          }}
          id="video"
          src=""
          playsInline></video>
        <img
          id="imageTag"
          style={{ display: 'none' }}
          src=""
          alt="pictureAlt"
        />
        <TempVideo ref={tempVideoRef} muted />

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent id="alert-dialog-content">
            <DialogContentText id="alert-dialog-description">
              <WarningContainer>
                <Grid item xs={12} className="text-center warning-container">
                  <Warning /> <br />
                  <p>{words.warning}</p>
                </Grid>
                <p>{words.dialogVideo}</p>
                <p> {words.dialogVideoTwo}</p>
              </WarningContainer>
            </DialogContentText>
          </DialogContent>
          <StyledDialogAction>
            <Button id="agree-btn" onClick={handleAgreeDialog} autoFocus>
              {words.okay}
            </Button>

            <Button id="disagree-btn" onClick={handleCloseDialog}>
              {words.cancel}
            </Button>
          </StyledDialogAction>
        </Dialog>

        <ModalHeader
          closeModal={handleCloseCompanyDialog}
          menuItem={footerMenuItem}
          style={{ display: openCompanyDialog ? 'flex' : 'none' }}
        />
        <CompanyDialog
          open={openCompanyDialog}
          fullScreen
          hideBackdrop
          PaperProps={{
            elevation: 0,
          }}
          onClose={handleCloseCompanyDialog}
          aria-labelledby="alert-company-title"
          aria-describedby="alert-company-description">
          <DialogContent id="alert-company-content">
            <div id="alert-company-description">
              <Grid
                item
                xs={12}
                className="company-container"
                id="company-container">
                {footerMenuItem === 'termsOfUse' && <TermsOfUse />}

                {footerMenuItem === 'privacyPolicy' && <PrivacyPolicy />}

                {footerMenuItem === 'howToUse' && currentLanguage && (
                  <HowToUse lang={currentLanguage} />
                )}
              </Grid>
            </div>
            <MainFooter
              onClickItem={onClickFooterItems}
              menuItem={footerMenuItem}
            />
          </DialogContent>
        </CompanyDialog>
      </div>
      <AlertModal
        alertPosition="bottom"
        closeSuccessAlert={() => setSnackbar({ ...snackbar, open: false })}
        showAlertError={snackbar.open}
        severity="error"
        alertTitle={snackbar.title}
        alertMessage={snackbar.message}
      />
    </div>
  );
}

export default App;
