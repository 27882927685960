import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

export const scaleDownVideo = async ({
  uploadedFile,
}: {
  uploadedFile: File;
}): Promise<string | undefined> => {
  const URL = window.URL || window.webkitURL;

  const splitUrl = window.location.href.split('/en/');
  const domainUrl = splitUrl[0] + '/';

  const ffmpeg = createFFmpeg({
    log: true,
    corePath: domainUrl + 'core/ffmpeg-core.js',
    wasmPath: domainUrl + 'core/ffmpeg-core.wasm',
    workerPath: domainUrl + 'core/ffmpeg-core.worker.js',
  });
  await ffmpeg.load();

  const videoUrl = URL.createObjectURL(uploadedFile);
  ffmpeg.FS('writeFile', 'userinput.mp4', await fetchFile(videoUrl));

  await ffmpeg.run(
    '-v',
    'error',
    '-i',
    'userinput.mp4',
    '-c:v',
    'libx264',
    '-filter:v',
    'scale=-2:1080',
    '-c:a',
    'copy',
    'output.mp4',
  );

  const files = ffmpeg.FS('readdir', '/');
  const videoPath = files.find((file) => file === 'output.mp4');

  if (!videoPath) return;

  const newVideoUrl = URL.createObjectURL(
    new Blob([ffmpeg.FS('readFile', videoPath)], { type: 'video/mp4' }),
  );

  ffmpeg.FS('unlink', 'userinput.mp4');
  ffmpeg.exit();

  return newVideoUrl;
};
