type Props = {
  [key: string]: string | number;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M25 84H41C41 88.4 37.4 92 33 92C28.6 92 25 88.4 25 84ZM17 80H49V72H17V80ZM63 42C63 57.28 52.36 65.44 47.92 68H18.08C13.64 65.44 3 57.28 3 42C3 25.44 16.44 12 33 12C49.56 12 63 25.44 63 42ZM82.48 33.48L77 36L82.48 38.52L85 44L87.52 38.52L93 36L87.52 33.48L85 28L82.48 33.48ZM73 28L76.76 19.76L85 16L76.76 12.24L73 4L69.24 12.24L61 16L69.24 19.76L73 28Z"
        fill="#036EB8"
      />
    </svg>
  );
};

export default Component;
