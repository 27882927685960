import React from 'react';
import words from '../../constants/words';
import { CopyrightLabel, FooterWrapper } from './elements';
import { BookIcon } from '../Icons';

export type Props = {
  onClickItem: (event: any) => void;
  menuItem: string;
};

const Component = ({
  onClickItem = () => {},
  menuItem = '',
}: Props): React.ReactElement => {
  return (
    <FooterWrapper className="footer_wrapper">
      <ul className="footer_navs">
        <li
          style={{
            display: menuItem === '' || menuItem === 'company' ? undefined : 'none',
          }}
          className={
            menuItem === 'howToUse' ? 'how_to_use active' : 'how_to_use'
          }
          onClick={() => onClickItem('howToUse')}>
          <BookIcon />
          {words.howToUse}
        </li>
        <li
          className={menuItem === 'termsOfUse' ? 'active' : undefined}
          onClick={() => onClickItem('termsOfUse')}>
          {words.termsOfUse}
        </li>
        <li
          className={menuItem === 'privacyPolicy' ? 'active' : undefined}
          onClick={() => onClickItem('privacyPolicy')}>
          {words.privacyPolicy}
        </li>
        <li
          className={menuItem === 'company' ? 'active' : undefined}
          onClick={() => onClickItem('company')}>
          {words.company}
        </li>
      </ul>
      <CopyrightLabel className="copyright">C 2024 ORGO, Inc.</CopyrightLabel>
    </FooterWrapper>
  );
};

export default Component;
