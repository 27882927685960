import { styled } from '@mui/material';
import React from 'react';
import { Back } from '../Icons';
import { BREAKPOINTS } from '../../constants/breakpoints';

export type headerProps = {
  uploadVideo: (event: any) => void;
  downloadVideo: (event: any) => void;
  videoUrl: string;
  isLoading?: boolean;
};

const HeaderContainer = styled('div')`
  text-align: left;
  height: 66px;
  max-height: 66px;
  border-bottom: 1px solid #8b949f;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  @media only screen and (min-width: ${BREAKPOINTS.md}px) {
    max-height: 80px;
    height: 80px;
  }
`;

const ClickableIcon = styled('div')`
  cursor: pointer;
`;

const BackIcon = styled(Back)`
  width: 35px;
  height: 35px;
  margin-left: 15px;

  @media only screen and (min-width: ${BREAKPOINTS.md}px) {
    width: 64px;
    height: 64px;
  }
`;

const Component = ({
  uploadVideo = () => {},
}: headerProps): React.ReactElement => {
  return (
    <HeaderContainer>
      <ClickableIcon onClick={uploadVideo}>
        <BackIcon />
      </ClickableIcon>
    </HeaderContainer>
  );
};

export default Component;
