type Props = {
  [key: string]: string | number;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="466"
      height="42"
      viewBox="0 0 466 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_696_7357)">
        <path
          d="M41.8812 10.3358H7.0845V34.7895H14.169V19.3862C14.169 18.3778 14.2441 17.7979 14.3693 17.6719C14.4944 17.5458 15.0702 17.4702 16.0716 17.4702H22.7806C23.7819 17.4702 24.3577 17.5458 24.4828 17.6719C24.608 17.7979 24.6831 18.3778 24.6831 19.3862V41.924H31.8177V19.3862C31.8177 18.3778 31.8928 17.7979 32.0179 17.6719C32.1431 17.5458 32.7189 17.4702 33.7202 17.4702H40.4292C41.4055 17.4702 41.9563 17.5458 42.1065 17.6719C42.2567 17.7979 42.3318 18.3778 42.3318 19.3862V41.924H49.3662V17.8484C49.3662 15.7811 48.6402 14.0164 47.1883 12.529C45.7363 11.0416 43.959 10.3105 41.9062 10.3105L41.8812 10.3358Z"
          fill="#036EB8"
        />
        <path
          d="M83.7874 10.3359H66.8647C64.8119 10.3359 63.0596 11.067 61.5826 12.5544C60.1056 14.0418 59.4047 15.7813 59.4047 17.8738V34.4368C59.4047 36.504 60.1307 38.2687 61.5826 39.7561C63.0345 41.2183 64.8119 41.9494 66.8647 41.9494H83.7874C85.8401 41.9494 87.5925 41.2183 89.0695 39.7561C90.5214 38.2939 91.2474 36.504 91.2474 34.4368V17.8738C91.2474 15.8065 90.5214 14.0418 89.0695 12.5544C87.6175 11.067 85.8401 10.3359 83.7874 10.3359ZM84.1629 32.8738C84.1629 33.8822 84.0878 34.462 83.9626 34.588C83.8375 34.7141 83.2617 34.7897 82.2603 34.7897H68.3667C67.3654 34.7897 66.7896 34.7141 66.6644 34.588C66.5392 34.462 66.4641 33.8822 66.4641 32.8738V19.3611C66.4641 18.3527 66.5392 17.7729 66.6644 17.6469C66.7896 17.5208 67.3654 17.4452 68.3667 17.4452H82.2603C83.2617 17.4452 83.8375 17.5208 83.9626 17.6469C84.0878 17.7729 84.1629 18.3527 84.1629 19.3611V32.8738Z"
          fill="#036EB8"
        />
        <path
          d="M125.118 10.3359H108.195C106.142 10.3359 104.39 11.067 102.913 12.5544C101.461 14.0166 100.735 15.7813 100.735 17.8738V22.2351C100.735 24.2771 101.461 26.0418 102.913 27.5292C104.365 29.0166 106.142 29.7729 108.195 29.7729H124.267C124.842 29.7729 125.193 29.8485 125.318 29.9746C125.443 30.1006 125.518 30.4536 125.518 31.0334V33.6048C125.518 34.1847 125.443 34.5376 125.318 34.6637C125.193 34.7897 124.842 34.8653 124.267 34.8653H109.071C108.496 34.8653 108.145 34.7897 108.02 34.6637C107.895 34.5376 107.82 34.1847 107.82 33.6048V33.2771H100.735V34.4872C100.735 36.5544 101.461 38.3191 102.913 39.8065C104.365 41.2687 106.142 41.9998 108.195 41.9998H125.118C127.171 41.9998 128.948 41.2687 130.425 39.8065C131.902 38.3443 132.653 36.5544 132.653 34.4872V30.1259C132.653 28.0838 131.902 26.3443 130.425 24.8317C128.948 23.3443 127.171 22.6132 125.118 22.6132H109.046C108.47 22.6132 108.12 22.5376 107.995 22.4116C107.87 22.2855 107.795 21.9326 107.795 21.3527V18.7813C107.795 18.2015 107.845 17.8485 107.995 17.7225C108.12 17.5964 108.47 17.5208 109.046 17.5208H124.242C124.817 17.5208 125.168 17.5964 125.293 17.7225C125.418 17.8485 125.493 18.2015 125.493 18.7813V19.109H132.628V17.899C132.628 15.8317 131.877 14.067 130.4 12.5796C128.923 11.1174 127.146 10.3611 125.093 10.3611H125.118V10.3359Z"
          fill="#036EB8"
        />
        <path
          d="M166.473 10.3358H142.091V17.4702H164.971C165.973 17.4702 166.548 17.5458 166.674 17.6719C166.799 17.7979 166.874 18.3778 166.874 19.3862V22.5374H142.116V34.4114C142.116 36.4786 142.842 38.2433 144.319 39.7307C145.771 41.1929 147.523 41.924 149.601 41.924H173.983V17.8484C173.983 15.7811 173.257 14.0164 171.78 12.529C170.328 11.0668 168.576 10.3105 166.498 10.3105H166.473V10.3358ZM166.849 34.7895H151.053C150.051 34.7895 149.475 34.7139 149.35 34.5879C149.225 34.4618 149.15 33.882 149.15 32.8736V28.9408H166.824V34.7643H166.849V34.7895Z"
          fill="#036EB8"
        />
        <path
          d="M192.884 10.3359H185.799V41.9242H192.884V10.3359Z"
          fill="#036EB8"
        />
        <path d="M192.884 0H185.799V7.18487H192.884V0Z" fill="#036EB8" />
        <path
          d="M211.559 34.6132C211.434 34.4872 211.358 33.9074 211.358 32.899V19.3864C211.358 18.378 211.434 17.7981 211.559 17.6721C211.684 17.546 212.26 17.4704 213.261 17.4704H236.092V10.3359H211.759C209.706 10.3359 207.954 11.067 206.477 12.5544C205.025 14.0166 204.299 15.7813 204.299 17.8738V34.4368C204.299 36.504 205.025 38.2687 206.477 39.7561C207.929 41.2183 209.706 41.9494 211.759 41.9494H236.142V34.8149H213.261C212.26 34.8149 211.684 34.7393 211.559 34.6132Z"
          fill="#036EB8"
        />
        <path
          d="M291.866 10.3361H274.518C272.465 10.3361 270.713 11.0672 269.236 12.5546C267.784 14.0168 267.058 15.7815 267.058 17.8739V34.437C267.058 36.5042 267.784 38.2689 269.236 39.7563C270.688 41.2185 272.465 41.9496 274.518 41.9496H298.951V0H291.866V10.3361ZM291.866 32.8739C291.866 33.8824 291.791 34.4622 291.666 34.5882C291.541 34.7143 290.965 34.7899 289.964 34.7899H276.07C275.069 34.7899 274.493 34.7143 274.368 34.5882C274.243 34.4622 274.168 33.8824 274.168 32.8739V19.3613C274.168 18.3529 274.218 17.7731 274.368 17.6471C274.493 17.521 275.069 17.4454 276.07 17.4454H289.964C290.965 17.4454 291.541 17.521 291.666 17.6471C291.791 17.7731 291.866 18.3529 291.866 19.3613V32.8739Z"
          fill="#036EB8"
        />
        <path
          d="M318.978 10.3359H311.893V41.9242H318.978V10.3359Z"
          fill="#036EB8"
        />
        <path d="M318.978 0H311.893V7.18487H318.978V0Z" fill="#036EB8" />
        <path
          d="M332.646 12.5544C331.194 14.0166 330.443 15.7813 330.443 17.8738V41.9494H337.528V19.3864C337.528 18.378 337.578 17.7981 337.728 17.6721C337.853 17.546 338.429 17.4704 339.43 17.4704H355.327V10.3359H337.928C335.876 10.3359 334.123 11.067 332.646 12.5544Z"
          fill="#036EB8"
        />
        <path
          d="M388.121 10.3359H371.198C369.145 10.3359 367.393 11.067 365.916 12.5544C364.464 14.0166 363.738 15.7813 363.738 17.8738V34.4368C363.738 36.504 364.464 38.2687 365.916 39.7561C367.368 41.2183 369.145 41.9494 371.198 41.9494H395.581V34.8149H372.7C371.699 34.8149 371.123 34.7393 370.998 34.6132C370.872 34.4872 370.822 33.9074 370.822 32.899V29.7477H395.581V17.8738C395.581 15.8065 394.855 14.0418 393.403 12.5544C391.951 11.0922 390.173 10.3359 388.121 10.3359ZM388.496 23.2939H370.822V19.3611C370.822 18.3527 370.872 17.7729 370.998 17.6469C371.123 17.5208 371.699 17.4452 372.7 17.4452H386.593C387.595 17.4452 388.171 17.5208 388.296 17.6469C388.421 17.7729 388.496 18.3527 388.496 19.3611V23.2939Z"
          fill="#036EB8"
        />
        <path
          d="M412.403 34.6132C412.278 34.4872 412.228 33.9074 412.228 32.899V19.3864C412.228 18.378 412.278 17.7981 412.403 17.6721C412.528 17.546 413.104 17.4704 414.105 17.4704H436.936V10.3359H412.603C410.551 10.3359 408.798 11.067 407.321 12.5544C405.869 14.0166 405.143 15.7813 405.143 17.8738V34.4368C405.143 36.504 405.869 38.2687 407.321 39.7561C408.773 41.2183 410.551 41.9494 412.603 41.9494H436.986V34.8149H414.105C413.104 34.8149 412.528 34.7393 412.403 34.6132Z"
          fill="#036EB8"
        />
        <path
          d="M466 17.4704V10.3359H453.784V0.655273H446.699V34.4116C446.699 36.4788 447.425 38.2435 448.877 39.7309C450.329 41.1931 452.106 41.9242 454.159 41.9242H466V34.7897H455.661C454.66 34.7897 454.084 34.7141 453.959 34.588C453.834 34.462 453.759 33.8822 453.759 32.8738V17.4704H466Z"
          fill="#036EB8"
        />
        <path d="M7.0845 34.79H0V41.9245H7.0845V34.79Z" fill="#036EB8" />
      </g>
      <defs>
        <clipPath id="clip0_696_7357">
          <rect width="466" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Component;
