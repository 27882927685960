import { styled } from '@mui/material';

export const LanguageContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  height: 54px;
  background-color: #fff;

  .main_logo {
    cursor: pointer;
    width: 250px;

    svg {
      max-width: 100%;
    }
  }

  .lang-switcher {
    text-align: right;
    padding-right: 25px;
  }

  .lang-switcher span {
    margin: 0px;
    background: #036eb8;
    background: #d3ddf0;
    padding: 10px;
    color: #0e0e20;
    font-family: MsGothic;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0.27px;
    cursor: pointer;
  }

  .lang-switcher svg {
    position: relative;
    top: 2px;
  }

  /* Mobile */
  @media only screen and (max-width: 600px) {
    height: 38px;
    padding: 8px;

    .main_logo {
      width: 154px;
    }
  }
`;

export const LanguageSection = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  .globe_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    h1 {
      color: #525664;
      /* bold */
      font-family: 'BIZ UDPGothic', sans-serif;
      font-weight: 700;

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      letter-spacing: 1.6px;
      margin: 0;
    }

    @media screen and (max-width: 600px) {
      h1 {
        display: none;
      }
    }
  }
`;

export const LangSwitcherWrapper = styled('div')`
  display: flex;
  align-items: stretch;

  span {
    font-family: 'BIZ UDPGothic';
    background-color: transparent;
    width: 59px;
    height: 40px;
    padding: 0 16px;
    color: #d9d9d9;
    cursor: pointer;

    /* bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 1.6px;

    display: flex;
    align-items: center;
  }

  span.active {
    color: #296eb2;
    border-bottom: 1px solid #296eb2;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const LangSwitcherMobileWrapper = styled('div')`
  display: none;

  .MuiButtonBase-root {
    font-family: 'BIZ UDPGothic';
    background-color: transparent;
    width: 100%;
    height: 40px;
    padding: 0 8px 0 16px;
    color: #d9d9d9;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    border-radius: 0;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      transform: rotate(90deg);
    }
  }

  .MuiButtonBase-root.active {
    color: #296eb2;
    border-bottom: 1px solid #296eb2;
  }

  .MuiPopper-root {
    .MuiButtonBase-root.active {
      border-bottom: 0;
    }
  }

  @media screen and (max-width: 600px) {
    display: block;

    .MuiButtonBase-root {
      height: 28px;
      padding: 0;
      justify-content: end;
      min-width: 53px;

      svg {
        height: 19px;
        margin-bottom: 3px;
      }
    }

    .dropdown_wrapper .MuiButtonBase-root {
      width: 100%;
      min-width: 100%;
      justify-content: start;
      padding: 0 0 0 17px;
    }
  }
`;
