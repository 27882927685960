import en from './en';
import ja from './ja';

let isEN = false;
if (
  window.location.href.indexOf('/en/') !== -1 ||
  window.location.href.indexOf('/en') !== -1
) {
  isEN = true;
} else {
  isEN = false;
}

const words = {
  en,
  ja,
};

export default words[isEN ? 'en' : 'ja'];
