const words = {
  eye: '目',
  face: '顔',
  black: '黒',
  blur: 'ぼかし',
  dark_skin: '肌色',
  gray: '黒',
  mosaic: 'モザイク',
  thin: '小',
  normal: '中',
  thick: '大',
  uploadLabel: '動画をアップロード',
  uploadLimit: '(最大50MB)',
  recordAVideo: '動画を撮影',
  reuseVideo: '最後のビデオを再利用する',
  signAgreementForm: '同意書の作成',
  successLabel: '成功',
  successText: 'ビデオがダウンロードされました。',
  targetBtn: 'カバー対象',
  typeBtn: 'マスクの種類',
  sizeBtn: 'カバーサイズ',
  infoLabel: '情報',
  infoText: 'カバー設定を選び再生ボタンを押してください。',
  compile: '再生',
  downloadVideo: 'ダウンロード',
  compiling: '再生中',
  playVideo: 'カバー設定を選び再生ボタンを押してください。',
  dialogVideo: 'ホーム画面に戻ります。録画したビデオは削除されます。',
  dialogVideoTwo: '本当に戻りますか?',
  cancel: 'キャンセル',
  okay: '戻る',
  warning: '警告',
  howToUseGuide: 'モザイクダイレクトの使いかた',
  howToUse: '使いかた',
  termsOfUse: '利用規約',
  serviceDescription: '第１条 サービス内容',
  serviceDescriptionContent:
    '本サイトは、写真や画像をオンラインでモザイク加工できるサービスを提供する。',
  feesDescription: '第２条 料金',
  feesDescriptionContent:
    '本サイトは、全てのコンテンツを完全無料で利用できることとする。',
  scopeOfLicense: '第３条 使用許諾範囲',
  scopeOfLicenseContent:
    '商用目的・非商用目的\n本サイトは、有償、無償問わず、自由に利用できることとする。※有償、無償問 わず、ホームページ、バナー、印刷物、チラシ、広告、看板、ディスプレイ等の 使用を許可する。',
  scopeOfLicenseContentTwo:
    '利用用途の制限\n本サイトの画像素材を「法令や公序良俗に反するコンテンツ」で利用することを 禁止する。',
  noNeedForRegistration: '第４条 報告不要',
  noNeedForRegistrationContent:
    '本サイトを利用する際、登録、報告、その他の手続きを不要とする。',
  prohibitedActions: '第５条 禁止事項（画像素材の利用）',
  prohibitedActionsContent: '再配布の禁止',
  prohibitedActionsContentTwo:
    '①本サイトの画像素材を素材として再配布することを禁止する。',
  prohibitedActionsContentThree:
    '②本サイトの画像素材を加工・改造して再配布することも禁止する。',
  prohibitedActionsContentFour: 'システムへの組込みの禁止',
  prohibitedActionsContentFive:
    '本サイトの画像素材をテンプレートの一部として利用すること、システムの一部 に組み込んで利用することを禁止する。その他、当サイトと競合する画像加工サ イトや画像加工システムでの利用を禁止する。',
  prohibitedActionsContentSix: '商標登録・意匠登録の禁止',
  prohibitedActionsContentSeven:
    '本サイトで作成した画像やロゴを商標登録・意匠登録することを禁止する。',
  authorityToCeaseUse: '第６条 画像素材の利用停止の権限',
  authorityToCeaseUseContent:
    '本サイトの画像素材の利用方法について、本サイトが不適切であると判断した場合、該当のユーザーの現在、および将来にわたる利用禁止の措置を取ることができるものとする。該当のユーザーは、利用禁止の要請を受けた場合、速やかに利用中の画像を削除しなければならないこととする。',
  disclaimer: '第７条 免責事項',
  disclaimerContent:
    '(1) 本サイトは、利用者のPC利用環境について一切関与せず、また一切の責任を負わないものとする。',
  disclaimerContentTwo:
    '(2) 本サイトを利用して生じた、損害・賠償について、本サイトは一切の責任を負わないものとする。',
  disclaimerContentThree:
    '(3) 本サイトで利用する写真・画像の著作権について、全て利用者自身・自己の責任に基づき管理・利用することとする。',
  disclaimerContentFour:
    '(4) 本サイトは、サイトの利用方法など技術的な質問に対するサポート義務を一切負わないこととする。',
  jurisdiction: '第８条 裁判管轄',
  jurisdictionContent:
    '本サイトの利用において、本サイトと利用者との間で生じた紛争については、本サイトの運営者の所在地を管轄する裁判所を第一審の合意管轄裁判所とする。',
  revision: '第９条 規約の改訂',
  revisionContent:
    '本サイトは、随時本規約を改訂することができるものとする。本規約を改訂しようとする場合、本サイト上で、随時利用者に告知するものとする。',
  established: '2024年3月1日制定',
  aboutTheProtectionOfPersonalInformation: 'お客様の個人情報の保護について',
  aboutTheProtectionOfPersonalInformationContent:
    '株式会社ORGO（以下「当社」といいます。）今日における個人情報の保護の重要性を強く自覚し、関係諸法令、ガイドライン等を遵守するとともに、お客様より寄せられる信頼に背くことのないよう、日々、社内体制を万全にすべく努めてまいります。当社では、かかる理解の下、今般、以下の通りプライバシーポリシー（個人情報保護に関する基本方針）を制定いたしました。なお、本ポリシーにおいて、個人情報とは、生存する個人に関する情報であって、当該記述等に含まれる氏名等の記述等により、特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることになるものを含みます。）のうち、お客様（当社製品をご購入頂いた方、当社サービスをご利用頂いた方その他これらに類する方の）情報をいいます。',
  introduction: 'はじめに',
  introductionContent:
    '当社は、個人情報保護の重要性を強く自覚するとともに、個人情報の取扱に全従業者をして日々細心の注意を払います。当社は、個人情報の保護に関する法律その他の関係諸法令・諸条例・各種ガイドライン等（以下「諸法令等」という。）　を常に注意を払うとともに、これを遵守する体制作りに取り組みます。さらに、当社の社内規則として個人情報取扱規程その他各種マニュアル等を制定し、全従業者が個人情報保護の重要性を強く自覚し、業務を遂行しております。そして、当社は、個人情報を以下のとおり取扱います。',
  purposeOfUse: '利用目的',
  purposeOfUserPersonalInformation: '個人情報の利用目的',
  purposeOfUseContent:
    '当社が保有する個人情報については、以下に記載している利用目的の範囲内で取扱います。\n(1) お客様個人情報の取扱いについて',
  aquisitionUse: '取得、利用および提供',
  aquisitionUseContent:
    '当社のすべての事業で取扱う個人情報および従業員の個人情報について、適正な取得、利用および提供を行い、特定した利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。\nまた、目的外利用を行なわないための適切な措置を講じます。\n利用目的を超えて個人情報の取扱いを行なう場合には、あらかじめご本人の同意をいただきます。',
  safetyManagementMeasures: '安全管理措置',
  safetyManagementMeasuresContent:
    '当社は、個人情報保護のための体制整備、従業員に対する教育・啓発活動、個人情報を取扱うエリアへの入退管理およびアクセス制御、ウイルス対策ソフトの導入等の情報セキュリティ施策によって、個人情報の安全管理措置を講じています。また、当社所定の保存期間が経過した個人情報は、適切な方法で確実に廃棄または消去します。\nこれらの安全管理措置が適切に講じられていることを担保するために第三者機関による情報セキュリティに関する認証（ISMS）を取得し、継続的に維持、改善していきます。',
  provisionToThirdParties: '第三者への提供および委託',
  provisionToThirdPartiesContent:
    '当社はご本人様の同意がない限り、個人情報を第三者に開示、提供することはいたしません。但し、利用目的を達成するため、当社と秘密保持契約（当社と同等の個人情報保護水準を十分に満たしていることを条件といたします）を締結している業務委託先に委託する場合や、人の生命、身体または財産の保護のために必要な場合であって、ご本人様の同意をいただくことが困難な場合、その他法令に基づき開示、提供を要請される場合はこの限りではありません。',
  responseToIncidents: '事故への対処',
  responseToIncidentsContent:
    '万が一個人情報漏洩等の事故が発生した場合、速やかにこれを公表、通知するとともに、徹底的な事実確認、原因究明に努め、被害を最小限度に留めるよう努めます。また、事故の原因等を徹底的に分析し適切な再発防止策を講じます。',
  handlingOfPersonalInformation: '（1）お客様個人情報の取扱いについて',
  collectionOfPersonalInformation: '個人情報の収集',
  collectionOfPersonalInformationContent:
    '当社は、必要な範囲において個人情報を収集することがあります。収集する個人情報は、以下に定める利用目的を達成するために必要な範囲内で適法かつ公正な手段により収集し、個人の権利利益を侵害するおそれのある個人情報は収集いたしません。',
  purposeOfUseOfPersonalInfo:
    '当社の事業活動に関連し、以下に定める利用目的の達成に必要な範囲内において個人情報を利用します。当該利用目的の範囲を超えた取得や、その他の目的で利用することはありません。',
  personalInfoFF: 'お客様の個人情報を以下の目的で利用します。',
  personalInfoPurposes: [
    'ご本人様の確認、ご契約内容の確認、ご利用料金の請求、サービスに関する通知（ご利用料金・ご利用サービス提供条件の変更の通知、停止・中止・契約解除の通知、約款の変更の通知等）、その他当社サービス提供に係わること',
    '電話、電子メール、郵便、インターネット等各種媒体を利用した当社のサービスに関するご案内、広告の配信、アンケート調査',
    '当社が提供するサービスの改善および当社サービスの利用者管理、サービス約款に違反する態様でのご利用を防止するため',
    'お客様からのご要望やご依頼内容等の確認、応対の品質向上およびサービス向上のために対応履歴を確認すること',
  ],
  browsingInfoFF:
    '取得した閲覧履歴や購買履歴を分析して、以下の目的で利用します。',
  browsingInfoPurposes: [
    '当社および当社の提携先のサービス、イベント等のご案内のため',
    '当社サービスに関する広告の表示のため',
    '当社サービスの利用状況の調査、サービス改善、新規サービスの開発のため',
  ],
  purposeOfUseOfPersonalInfoContent:
    '1. ご本人様の確認、ご契約内容の確認、ご利用料金の請求、サービスに関する通知（ご利用料金・ご利用サービス提供条件の変更の通知、停止・中止・契約解除の通知、約款の変更の通知等）、その他当社サービス提供に係わること',
  purposeOfUseOfPersonalInfoContentTwo:
    '2. 電話、電子メール、郵便、インターネット等各種媒体を利用した当社のサービスに関するご案内、広告の配信、アンケート調査',
  purposeOfUseOfPersonalInfoContentThree:
    '3. 当社が提供するサービスの改善および当社サービスの利用者管理、サービス約款に違反する態様でのご利用を防止するため',
  purposeOfUseOfPersonalInfoContentFour:
    '4. お客様からのご要望やご依頼内容等の確認、応対の品質向上およびサービス向上のために対応履歴を確認すること',
  purposeOfUseOfPersonalInfoContentFive:
    '取得した閲覧履歴や購買履歴を分析して、以下の目的で利用します。',
  purposeOfUseOfPersonalInfoContentSix:
    '1. 当社および当社の提携先のサービス、イベント等のご案内のため',
  purposeOfUseOfPersonalInfoContentSeven:
    '2. 当社サービスに関する広告の表示のため',
  purposeOfUseOfPersonalInfoContentEight:
    '3. 当社サービスの利用状況の調査、サービス改善、新規サービスの開発のため',
  outsourcingOfPersonalInfo: '個人情報の委託',
  outsourcingOfPersonalInfoContent:
    '情報処理などの業務の一部を外部に委託する際に、個人情報を提供する場合があります。個人情報の提供を行う場合には、十分な個人情報保護水準を確保していることを条件として委託先を選定し、機密保持契約を結んだ上で委託します。',
  thirdPartyProvision: '個人情報の第三者提供',
  thirdPartyProvisionContent:
    'ご提供いただいた個人情報は、以下のいずれかに該当する場合を除き、第三者に対して開示いたしません。',
  thirdPartyProvisionContentTwo: 'ご本人の同意がある場合',
  thirdPartyProvisionContentThree:
    '人の生命、身体または財産の保護のために必要な場合であって、ご本人のご同意をいただくことが困難な場合',
  thirdPartyProvisionContentFour:
    'その他法令にもとづき開示・提供を求められた場合',
  insufficientInfo: '情報不足の場合',
  insufficientInfoContent:
    'ご提供いただく個人情報が不足している場合には、当社のサービスを提供できない場合があります。',
  requestForDisclosure: '個人情報の開示等の請求',
  requestForDisclosureContent:
    'ご本人様または代理人は利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止（以下、「開示等」といいます）を求めることができます。',
  requestForDisclosureContentTwo:
    '開示等の請求を受けた場合は、当社の規定に従ってすみやかに対応します。',
  requestForDisclosureContentThree:
    '開示等の手続きにあたり、ご本人であることまたは代理人であることを確認します。',
  requestForDisclosureContentFour:
    '請求方法は開示等の請求依頼よりお問い合わせください。',
  requestForDisclosureContentFive:
    '以下に示すいずれかに該当する場合、開示は差し控えます。なお、その場合は遅滞なく理由を付記して通知します。',
  requestForDisclosureContentSix:
    '本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合',
  requestForDisclosureContentSeven:
    '業務の適正な実施に著しい支障を及ぼすおそれがある場合',
  requestForDisclosureContentEight: '法令に違反することとなる場合',
  managementOfPersonalInfo: '個人情報の管理',
  managementOfPersonalInfoContent:
    '個人情報への不当なアクセスまたは個人情報の紛失、破壊、改ざん、漏洩などの危険に対して、技術面および組織面において適切な安全対策を講じ、保管・管理を行います。',
  aboutCookies: 'Cookieについて',
  aboutCookiesContent:
    '当社のWebサイトの利便性の向上のため、Webサイトで標準的に利用されている”Cookie”と呼ばれるファイルが、Webブラウザを通じてお客様のコンピュータに保存され、当社により参照されることがあります。Cookieにより収集される情報には、最後にサイトアクセスした日時・訪問回数等の情報が含まれますが、この情報は、Webサイトの利用状況の把握等のため、当社のプライバシーポリシーに従い使用されます。なお、お客様がWebブラウザの設定でCookieを受け取らない措置をとることは可能ですが、方法については、お使いのWebブラウザの提供元にお問い合せ下さい。また、Cookieを受け取らない措置により、当社のWebサイトの一部の機能が使用できないことがありますので、ご了承下さい。',
  retentionPeriod: '個人情報の保存期間',
  retentionPeriodContent:
    'サービスの利用契約が終了し、当社所定の保存期間が経過した時点で、利用者の個人情報を消去するものとします。ただし、法令の規定および個人を特定できない情報として保有する統計データ等は除きます。',
  inquiriesRegardingPersonalInfo: '個人情報に関するお問い合わせ先',
  inquiriesRegardingPersonalInfoContent: '株式会社ORGO',
  inquiriesRegardingPersonalInfoContentTwo: {
    label: 'URL: ',
    description: 'https://orgo.co.jp/contact/',
  },
  other: 'その他',
  otherContent:
    '本プライバシーポリシーは、より良い内容を目指すため、適宜内容が修正されることがあります。修正後のプライバシーポリシーは、修正後直ちに本サイトに掲載いたします。',
  privacyPolicy: 'プライバシーポリシー',
  company: '運営会社',
  fileSizeLimitError: '50MB以下のファイルをアップロードしてください。',
  errorTitle: 'エラー',
  error: {
    fileFormatError: 'ファイルのアップロードに失敗しました。',
  },
  steps: {
    step1: {
      title: '動画を',
      subTitle: 'アップロード',
    },
    step2: {
      title: 'モザイクの',
      subTitle: '種類を選択',
    },
    step3: {
      title: 'ダウンロード',
    },
  },
  topBtmInfo: {
    text1:
      '動画内の人物を自動認識して顔をモザイク加工します。プライバシーを考慮した動画を',
    text2: '簡単に作成することができます。',
  },
  howToUsePage: {
    step1: {
      title: '<span>step.1</span>モザイクダイレクトをホーム画面に追加する',
      images: [
        {
          description: `<p>下記のリンクからSafari（ブラウザ）でモザイクダイレクトを開きます。<a href="https://mosaicdirect.jp/" target="_blank">https://<span>mosaicdirect.jp/</a></p>`,
        },
        {
          description: `<p>Safari（ブラウザ）上部の共有マークより<mark class="highlight">[ホーム画面に追加］</mark>をします。</p>`,
        },
        {
          description: `<p>モザイクダイレクトがホーム画面に追加されました。<br>追加されたアイコンをタップして起動してください。</p>`,
        },
      ],
    },
    step2: {
      title: '<span>step.2</span>動画を撮影またはアップロードする',
      subTitle: `<mark class="highlight">初回のアップロードはオンライン状態で行なってください。</mark><br />
      一度オンライン状態で動画のアップロードを行うと、オフライン状態でもアプリを利用することができます。`,
      images: [
        {
          description: `<p>動画を撮影済みの場合は<mark class="highlight">[動画をアップロード］</mark>をタップし、カメラロールより選択してください。</p>`,
        },
        {
          description: `<p>これから動画を撮影する場合は<mark class="highlight">[動画を撮影］</mark>をタップしてください。
          カメラが起動します。</p>`,
        },
      ],
    },
    step3: {
      title: '<span>step.3</span>カバーモードを設定する',
      images: [
        {
          description: `<p>カバーモードの設定を行います。<br />
          この設定は後から変更ができます。</p>`,
        },
        {
          description: `<p><mark class="highlight">カバー対象</mark><br />
          <mark class="highlight">目</mark> …目元のみをカバーします。<br />
          <mark class="highlight">顔</mark> …全顔をカバーします。</p>`,
        },
        {
          description: `<p><mark class="highlight">マスクの種類</mark><br />
          <mark class="highlight">モザイク/ぼかし/黒/肌色</mark>の4種類から選択できます。</p>`,
        },
        {
          description: `<p><mark class="highlight">カバーサイズ</mark><br />
          カバーの範囲を<mark class="highlight">小/中/大</mark>の3種類から選択できます。</p>`,
        },
      ],
    },
    step4: {
      title: '<span>step.4</span>動画を確認し、ダウンロードする',
      images: [
        {
          description: `<p><mark class="highlight">[再生］</mark>をタップして、加工された動画を確認してください。<br />
          さらにカバーを変更することができます。変更後はもういちど<mark class="highlight">[再生］</mark>をタップして確認してください。</p>`,
        },
        {
          description: `<p>確認後、動画を保存することができます。<br />
          <mark class="highlight">[ダウンロード］</mark>をタップしてください。動画はiPad本体の【ファイル＞ダウンロード】フォルダへ保存されます。</p>`,
        },
        {
          description: `<p><mark class="highlight">[ダウンロード］</mark>をタップした後に上のような画面が表示された場合は、次の操作を行なってください。<br />
          「その他...」をタップします。</p>`,
        },
        {
          description: `<p><mark class="highlight">[ビデオを保存］</mark>をタップします。保存された動画は写真アプリから確認することができます。</p>`,
        },
        {
          description: `<p><mark class="highlight">[完了］</mark>をタップすると元の画面に戻ります。</p>`,
        },
      ],
    },
    environment: {
      title: '推奨環境',
      bullets: [
        {
          title: 'オペレーティングシステム (OS)',
          list: [
            'iOS: バージョン17以降',
            'iPadOS: バージョン17以降',
            'macOS: macOS 13.0 (Ventura) 以降',
            'Windows: Windows 10以降',
          ],
        },
        {
          title: 'ブラウザ',
          list: ['Google Chrome: 最新バージョン', 'Safari: 最新バージョン'],
        },
        {
          title: 'ハードウェア',
          list: [
            'RAM: 8GB 以上',
            'ストレージ: 500MB 以上の空き容量',
            'チップセット: Appleデバイスの場合はM1以降。その他の場合は同等のARMベースまたはx86プロセッサ',
            '画面解像度: 1280x720px以上、最大1920pxの幅まで',
            'カメラ: 顔認識精度を高めるために12MP以上',
          ],
        },
        {
          title: 'その他',
          list: ['JavaScript: 有効'],
        },
      ],
    },
    troubleshoot: {
      headerTitle: `困ったときは <br />
      -Q＆A-`,
      subTitle: '設定方法',
      question: `50MB以下の動画をアップロードしようとしても、
                <mark class="red">
                  「50MB以下のファイルをアップロードしてください」
                </mark>
                というエラーが表示されてアップロードできません。`,
      answer: `iPhoneやiPadのデフォルトの動画撮影フォーマット設定が<mark class="red">「高効率」</mark>になっている場合、アップロード時にファイルサイズが大きくなり（約15MB増加）、その結果、アプリ側でエラーが発生します。
                <br />
                撮影フォーマットをあらかじめ
                <mark class="highlight">「互換性優先」</mark>
                に設定することで解決します。`,
      images: [
        {
          description:
            '<p>ホーム画面の設定アプリから[カメラ]を開き、［フォーマット］を選択</p>',
        },
        {
          description:
            '<p>［高効率］にチェックが入っている場合、［互換性優先］を選択</p>',
        },
        {
          description: `<p>［互換性優先］にチェックが入ったことを確認し、設定アプリを閉じます。
もういちど撮影とアップロードを行なってください。</p>`,
        },
      ],
    },
    returnToText: 'ホームへもどる',
  },
};

export default words;
