import { styled } from '@mui/material';

export const StepsWrapper = styled('div')`
  margin: 40px 0 32px;

  display: flex;
  align-items: start;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;

  [class^='step_box'] {
    position: relative;
    width: 120px;
    max-width: 100%;

    svg {
      position: absolute;
      right: -55px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child::after {
      display: none;
    }

    h1 {
      color: #296eb2;
      font-family: 'BIZ UDPGothic';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      margin: 0;

      span {
        display: block;
        color: #525664;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 0.8px;

        &:first-child {
          margin-top: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    margin: 24px 0 16px;

    [class^='step_box'] {
      h1 {
        span {
          font-size: 10px;
        }
      }

      svg {
        right: -13px;
      }
    }
  }
`;
