type Props = {
  [key: string]: string | number;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M33.0003 63.0358C49.5882 63.0358 63.0353 49.5887 63.0353 33.0008C63.0353 16.4129 49.5882 2.96582 33.0003 2.96582C16.4125 2.96582 2.96533 16.4129 2.96533 33.0008C2.96533 49.5887 16.4125 63.0358 33.0003 63.0358Z"
        fill="#8B949F"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M30.0834 43L20.3334 33.25M20.3334 33.25L30.0834 23.5M20.3334 33.25H45.6667"
        stroke="white"
        strokeWidth="4.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Component;
