type Props = {
  [key: string]: string | number;
};

export const UploadIcon = (props: Props): React.ReactElement => {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.4999 10.7057H16.4691V24.2981C16.4691 24.4748 16.6138 24.6195 16.7906 24.6195H19.2013C19.3781 24.6195 19.5227 24.4748 19.5227 24.2981V10.7057H22.4999C22.7691 10.7057 22.9178 10.3963 22.7531 10.1873L18.2531 4.49003C18.223 4.45161 18.1846 4.42054 18.1407 4.39917C18.0969 4.3778 18.0487 4.3667 17.9999 4.3667C17.9511 4.3667 17.903 4.3778 17.8591 4.39917C17.8153 4.42054 17.7769 4.45161 17.7468 4.49003L13.2468 10.1833C13.0821 10.3963 13.2307 10.7057 13.4999 10.7057ZM32.7053 23.0927H30.2946C30.1178 23.0927 29.9731 23.2374 29.9731 23.4141V29.6016H6.02672V23.4141C6.02672 23.2374 5.88207 23.0927 5.70529 23.0927H3.29457C3.11779 23.0927 2.97314 23.2374 2.97314 23.4141V31.3695C2.97314 32.0807 3.5477 32.6552 4.25886 32.6552H31.741C32.4522 32.6552 33.0267 32.0807 33.0267 31.3695V23.4141C33.0267 23.2374 32.8821 23.0927 32.7053 23.0927Z"
        fill="#296EB2"
      />
    </svg>
  );
};
