import { Dialog, styled } from '@mui/material';
import { BREAKPOINTS } from './constants/breakpoints';

export const TempVideo = styled('video')({
  visibility: 'hidden',
  width: 0,
  height: 0,
});

export const UploadWrapper = styled('div')`
  width: 528px;
  max-width: 100%;
  margin: auto;

  @media screen and (max-width: 600px) {
    width: 320px;
    padding: 0 8px;
  }
`;

export const UploadPanelWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  span {
    flex: 1;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .ant-upload {
    background-color: #fff;
    border-radius: 10px;
    border: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ant-upload-wrapper {
    display: inline-block;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  && p.ant-upload-text {
    color: #033653;
    text-align: center;
    font-family: MsGothic;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 0;
    @media only screen and (max-width: 556px) {
      font-size: 18px;
    }
  }

  p.ant-upload-hint#ant-upload-hint {
    color: var(--DS-gray-dark, #8b949f);
    text-align: center;
    font-family: MsGothic;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 9px;
  }

  && .ant-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .upload_title {
    color: #296eb2;
    text-align: center;
    /* bold */
    font-family: 'BIZ UDPGothic', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 1.6px;

    span {
      display: block;
      font-size: 10px;
      margin-top: 8px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 600px) {
    .upload_title {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 430px) {
    div.ant-upload-drag-container {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  svg {
    width: 62px;
    @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
      width: 45px;
    }
  }
`;

export const UploadPanelContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  /* fallback in case svh is not supported */
  /* height: calc(100svh - 183px - 85px - 40px - 105px); */
  /* 183px = header, 85px = button,  40px = panel margin bottom, 105px footer */
  height: calc(100svh - 490px);
  max-height: 632px;
  &&& .ant-upload-hint {
    font-size: 16px;
  }

  /* IPAD AIR 820 x 1180 */
  @media screen and (max-width: 1180px) {
    height: calc(100svh - 570px);
  }

  @media screen and (max-width: 600px) {
    height: calc(100svh - 440px);
  }
`;

export const LoadingButtonWrapper = styled('div')`
  display: none;

  label {
    text-transform: capitalize;
    display: flex;
    height: 160px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    background: linear-gradient(
        109deg,
        #2b87e1 24.03%,
        #276baf 50%,
        #093c84 75.97%
      ),
      linear-gradient(180deg, #2b87e1 0%, #276baf 50%, #093c84 100%);
    box-shadow: 0px 2px 6px 0px rgba(39, 128, 255, 0.05);
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    span {
      color: #fff;
      font-family: 'BIZ UDPGothic';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1.6px;
    }
    span.MuiButton-startIcon {
      margin: 0;
    }
  }

  /* IPAD AIR 5th Gen 820 x 1180 PX */
  @media screen and (max-width: 1180px) {
    display: block;
  }

  @media screen and (max-width: 600px) {
    label {
      height: 100px;

      span {
        font-size: 14px;
      }
    }
  }
`;

export const TopBtmInfoWrapper = styled('div')`
  width: 400px;
  max-width: 100%;
  margin: 32px auto 0;
  position: relative;

  .how_to_use {
    font-family: 'BIZ UDPGothic';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.5px;
    display: none;
  }

  .top_btm_info_box.en .how_to_use {
    font-size: 9px;
  }

  .top_btm_info_box {
    p.pc_tab {
      display: block;
    }
    p.mobile {
      display: none;
    }

    p {
      margin: 0;
      color: #525664;
      text-align: center;
      /* body */
      font-family: 'BIZ UDPGothic';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.8px;

      span {
        display: block;
      }
    }
  }

  @media screen and (max-width: 810px) {
    /* Fix overlapping issue for english text */
    .top_btm_info_box.en {
      p {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    position: relative;
    width: 312px;
    margin: 20px auto 0;
    padding-left: 91px;

    .how_to_use {
      display: block;
      cursor: pointer;
      width: 80px;
      height: 80px;
      left: 5px;
      bottom: 0;
      background: #fff;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2px;

      color: #525664;

      border: 1px solid #525664;
      box-shadow: 0px 2px 6px 0px rgba(39, 128, 255, 0.05);

      font-size: 10px;

      svg {
        width: 30px;
      }

      position: absolute;
      top: -2px;
    }

    .how_to_use.active {
      text-decoration: underline;
    }

    .top_btm_info_box {
      p.pc_tab {
        display: none;
      }
      p.mobile {
        display: block;
      }

      p {
        text-align: left;
        font-size: 10px;

        span {
          display: inline-block;
        }
      }
    }

    .top_btm_info_box.en {
      p {
        font-size: 8px;
      }
    }
  }
`;

export const CompanyDialog = styled(Dialog)`
  z-index: 1;
  top: 95px;
  @media only screen and (max-width: 600px) {
    top: 79px;
  }
`;
