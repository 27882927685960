import { styled } from '@mui/material';

export const StepBoxWrapper = styled('div')`
  font-family: 'BIZ UDPGothic';
  position: relative;
  display: flex;
  padding: 16px 16px 40px 16px;
  gap: 16px;
  flex-direction: column;

  width: 100%;
  max-width: 100%;
  margin: auto;

  border-radius: 10px;
  background-color: #fff;

  .highlight {
    color: #296eb2;
    background: none;
  }

  .red {
    color: #b3261e;
    background: none;
  }

  .sub_title p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.8px;
    margin: 0;
    padding: 0;
  }

  .title {
    h1 {
      margin: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1.6px;
      color: #296eb2;

      span {
        padding-right: 16px;
      }
    }
  }

  .scrolling_wrapper {
    display: grid;
    grid-auto-columns: 291px;
    grid-gap: 20px;
    grid-auto-flow: column;
    overflow-x: auto;
    overflow-y: hidden;
    overscroll-behavior-inline: contain;
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 275px;
    max-width: 100%;
    margin: 0 auto;

    .images {
      position: relative;
      height: 368px;
      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }

      svg {
        position: absolute;
        top: 50%;
        right: -30px;
        transform: translate(0, -50%);
        color: #d9d9d9;
        font-size: 24px;
      }
    }

    .image_detail {
      width: 267px;
      max-width: 100%;
      padding-top: 8px;
      white-space: normal;

      p {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 0.8px;
        color: #525664;
      }
      a {
        text-decoration: underline;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.8px;
        color: #296eb2;
      }
    }
  }

  .environment {
    h2 {
      margin: 0;
      padding: 0;
      font-family: 'BIZ UDPGothic';
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 5%;
      color: #296eb2;
    }
    ul {
      padding: 0;
      margin: 0 0 20px 20px;

      li {
        font-family: 'BIZ UDPGothic';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #525664;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 16px 8px 20px;

    sub_title p {
    }

    .title {
      h1 {
        span {
          display: block;
          margin-bottom: 8px;
        }
      }
    }

    .scrolling_wrapper {
      grid-gap: 24px;
      grid-auto-columns: auto;
    }

    .details {
      width: 216px;

      .images {
        height: 267px;

        img {
        }

        svg {
          right: -32px;
        }
      }

      .image_detail {
        p {
        }
        a {
          display: block;
          span {
            display: block;
          }
        }
      }
    }
  }
`;

export const TroubleshootWapper = styled('div')`
  position: relative;
  background-color: rgba(0, 122, 255, 0.15);
  padding: 40px 0;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 40px;
    height: 100%;
    background-color: rgba(0, 122, 255, 0.15);
  }

  &:before {
    right: -40px;
  }
  &:after {
    left: -40px;
  }

  @media screen and (max-width: 600px) {
    padding: 16px 0;

    &:before,
    &:after {
      width: 16px;
    }
    &:before {
      right: -16px;
    }
    &:after {
      left: -16px;
    }
  }
`;

export const TroubleshootTitleWrapper = styled('div')`
  .title h1 {
    color: #296eb2;
    text-align: center;
    font-family: 'BIZ UDPGothic';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 1.6px;
    margin: 0 0 40px;

    span {
      display: block;
    }
  }

  @media screen and (max-width: 600px) {
    .title h1 {
      margin: 0 0 16px;
    }
  }
`;
export const TroubleshootDetailWrapper = styled('div')`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      gap: 16px;

      font-family: 'BIZ UDPGothic';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      letter-spacing: 1.6px;

      padding: 8px;

      span {
        color: #296eb2;
      }

      .detail {
        color: #525664;
      }
    }

    li.answer {
      background-color: #f8f8f8;
      border-radius: 10px;
    }
  }
`;
