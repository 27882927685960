type Props = {
  [key: string]: string | number;
};

export const PlayIcon = (props: Props): React.ReactElement => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M18 10.5L-9.78799e-07 20.8923L-7.02746e-08 0.107695L18 10.5Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
