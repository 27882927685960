import {
  LangSwitcherMobileWrapper,
  LangSwitcherWrapper,
  LanguageContainer,
  LanguageSection,
} from './elements';
import { Logo, GlobalIcon } from '../../Icons';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { useRef, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

type LanguageSwitcherProps = {
  currentLanguage: 'ja' | 'en';
  onLangSwitch: (lang: 'ja' | 'en') => void;
  onLogoClick: () => void;
};

const LanguageSwitcher = ({
  currentLanguage,
  onLangSwitch,
  onLogoClick,
}: LanguageSwitcherProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: Event | React.SyntheticEvent,
    lang?: 'ja' | 'en',
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    if (lang) {
      onLangSwitch(lang);
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  return (
    <LanguageContainer>
      <div className="main_logo" onClick={onLogoClick}>
        <Logo />
      </div>
      <LanguageSection>
        <div className="globe_box">
          <GlobalIcon />
          <h1>Language</h1>
        </div>

        {/* DESKTOP & TABLET */}
        <LangSwitcherWrapper>
          <span
            className={currentLanguage === 'en' ? 'active' : ''}
            onClick={(e) => {
              e.preventDefault();
              onLangSwitch('en');
            }}>
            EN
          </span>
          <span
            className={currentLanguage === 'ja' ? 'active' : ''}
            onClick={(e) => {
              e.preventDefault();
              onLangSwitch('ja');
            }}>
            JA
          </span>
        </LangSwitcherWrapper>
        {/* END OF DESKTOP & TABLET */}

        {/* MOBILE */}
        <LangSwitcherMobileWrapper>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className="active">
            {currentLanguage} <PlayArrowIcon />
          </Button>
          <Popper
            className="dropdown_wrapper"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{
              zIndex: 999,
              width: '65px',
            }}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}>
                      <MenuItem
                        className={currentLanguage === 'en' ? 'active' : ''}
                        onClick={(e) => handleClose(e, 'en')}>
                        EN
                      </MenuItem>
                      <MenuItem
                        className={currentLanguage === 'ja' ? 'active' : ''}
                        onClick={(e) => handleClose(e, 'ja')}>
                        JA
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </LangSwitcherMobileWrapper>
        {/* END OF MOBILE */}
      </LanguageSection>
    </LanguageContainer>
  );
};

export default LanguageSwitcher;
