import React from 'react';
import { styled } from '@mui/material';
import words from '../../constants/words';
import {
  AnchorWrapper,
  PrivacyPolicyWrapper,
  UnorderedListWrapper,
  Wrapper,
} from './elements';

const UnorderedList = styled('ul')`
  padding-inline-start: 20px;
  margin: 0;
`;

const UnorderedListItem = styled('li')``;

const OrderedList = styled('ol')`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-inline-start: 25px;
`;

const TitlePrivacyWrapper = styled('div')`
  white-space: pre-line;
  max-width: 688px;
`;

const Component = (): React.ReactElement => {
  return (
    <PrivacyPolicyWrapper>
      <TitlePrivacyWrapper id="title-privacy">
        <p className="title-privacy">
          {words.aboutTheProtectionOfPersonalInformation}
        </p>
        <p className="sub">
          {words.aboutTheProtectionOfPersonalInformationContent}
        </p>
        <p className="title-privacy">{words.introduction}</p>
        <p className="sub">{words.introductionContent}</p>
        <p className="title-privacy">{words.purposeOfUse}</p>
        <p className="sub">{words.purposeOfUseContent}</p>
        <p className="title-privacy">{words.aquisitionUse}</p>
        <p className="sub">{words.aquisitionUseContent}</p>
        <p className="title-privacy">{words.safetyManagementMeasures}</p>
        <p className="sub">{words.safetyManagementMeasuresContent}</p>
        <p className="title-privacy">{words.provisionToThirdParties}</p>
        <p className="sub">{words.provisionToThirdPartiesContent}</p>
        <p className="title-privacy">{words.responseToIncidents}</p>
        <Wrapper>
          <UnorderedListWrapper>
            <li>{words.responseToIncidentsContent}</li>
          </UnorderedListWrapper>
        </Wrapper>
        <p className="title-privacy">
          &nbsp;&nbsp;&nbsp;{words.handlingOfPersonalInformation}
        </p>
        <p className="title-privacy">{words.collectionOfPersonalInformation}</p>
        <p className="sub">{words.collectionOfPersonalInformationContent}</p>
        <p className="title-privacy">
          {words.purposeOfUserPersonalInformation}
        </p>
        <p className="sub">
          {words.purposeOfUseOfPersonalInfo}
          <UnorderedList>
            <UnorderedListItem>
              {words.personalInfoFF}
              <OrderedList>
                {words.personalInfoPurposes.map((item) => (
                  <li>{item}</li>
                ))}
              </OrderedList>
            </UnorderedListItem>
            <UnorderedListItem>
              {words.browsingInfoFF}
              <OrderedList>
                {words.browsingInfoPurposes.map((item) => (
                  <li>{item}</li>
                ))}
              </OrderedList>
            </UnorderedListItem>
          </UnorderedList>
        </p>
        <p className="title-privacy">{words.outsourcingOfPersonalInfo}</p>
        <p className="sub">{words.outsourcingOfPersonalInfoContent}</p>
        <p className="title-privacy">{words.thirdPartyProvision}</p>
        <p className="sub">{words.thirdPartyProvisionContent}</p>
        <ul className="sub-lists">
          <li>
            <p className="sub">{words.thirdPartyProvisionContentTwo}</p>
          </li>
          <li>
            <p className="sub">{words.thirdPartyProvisionContentThree}</p>
          </li>
          <li>
            <p className="sub">{words.thirdPartyProvisionContentFour}</p>
          </li>
        </ul>
        <p className="title-privacy">{words.insufficientInfo}</p>
        <p className="sub">{words.insufficientInfoContent}</p>
        <p className="title-privacy">{words.requestForDisclosure}</p>
        <p className="sub">
          {words.requestForDisclosureContent}

          <UnorderedList>
            <UnorderedListItem>
              {words.requestForDisclosureContentTwo}
            </UnorderedListItem>
            <UnorderedListItem>
              {words.requestForDisclosureContentThree}
            </UnorderedListItem>
            <UnorderedListItem>
              {words.requestForDisclosureContentFour}
            </UnorderedListItem>
            <UnorderedListItem>
              {words.requestForDisclosureContentFive}
              <OrderedList>
                <li>{words.requestForDisclosureContentSix}</li>
                <li>{words.requestForDisclosureContentSeven}</li>
                <li>{words.requestForDisclosureContentEight}</li>
              </OrderedList>
            </UnorderedListItem>
          </UnorderedList>
        </p>
        <p className="title-privacy">{words.managementOfPersonalInfo}</p>
        <p className="sub">{words.managementOfPersonalInfoContent}</p>
        <p className="title-privacy">{words.aboutCookies}</p>
        <p className="sub">{words.aboutCookiesContent}</p>
        <p className="title-privacy">{words.retentionPeriod}</p>
        <p className="sub">{words.retentionPeriodContent}</p>
        <p className="title-privacy">{words.inquiriesRegardingPersonalInfo}</p>
        <p className="sub orgo-url">
          {words.inquiriesRegardingPersonalInfoContent}
        </p>
        <p className="sub orgo-url">
          {words.inquiriesRegardingPersonalInfoContentTwo.label}{' '}
          <AnchorWrapper
            href={words.inquiriesRegardingPersonalInfoContentTwo.description}
            target="_blank">
            {words.inquiriesRegardingPersonalInfoContentTwo.description}
          </AnchorWrapper>
        </p>
        <p className="title-privacy">{words.other}</p>
        <Wrapper>
          <UnorderedListWrapper>
            <li>{words.otherContent}</li>
          </UnorderedListWrapper>
        </Wrapper>
        <p className="established">{words.established}</p>
      </TitlePrivacyWrapper>
    </PrivacyPolicyWrapper>
  );
};

export default Component;
