import ja_step1_1 from '../../assets/images/ja/how_to_use/step1/step1-1.png';
import ja_step1_2 from '../../assets/images/ja/how_to_use/step1/step1-2.png';
import ja_step1_3 from '../../assets/images/ja/how_to_use/step1/step1-3.png';

import en_step1_1 from '../../assets/images/en/how_to_use/step1/step1-1.png';
import en_step1_2 from '../../assets/images/en/how_to_use/step1/step1-2.png';
import en_step1_3 from '../../assets/images/en/how_to_use/step1/step1-3.png';

import ja_step2_1 from '../../assets/images/ja/how_to_use/step2/step2-1.png';
import ja_step2_2 from '../../assets/images/ja/how_to_use/step2/step2-2.png';

import en_step2_1 from '../../assets/images/en/how_to_use/step2/step2-1.png';
import en_step2_2 from '../../assets/images/en/how_to_use/step2/step2-2.png';

import ja_step3_1 from '../../assets/images/ja/how_to_use/step3/step3-1.png';
import ja_step3_2 from '../../assets/images/ja/how_to_use/step3/step3-2.png';
import ja_step3_3 from '../../assets/images/ja/how_to_use/step3/step3-3.png';
import ja_step3_4 from '../../assets/images/ja/how_to_use/step3/step3-4.png';

import en_step3_1 from '../../assets/images/en/how_to_use/step3/step3-1.png';
import en_step3_2 from '../../assets/images/en/how_to_use/step3/step3-2.png';
import en_step3_3 from '../../assets/images/en/how_to_use/step3/step3-3.png';
import en_step3_4 from '../../assets/images/en/how_to_use/step3/step3-4.png';

import ja_step4_1 from '../../assets/images/ja/how_to_use/step4/step4-1.png';
import ja_step4_2 from '../../assets/images/ja/how_to_use/step4/step4-2.png';
import ja_step4_3 from '../../assets/images/ja/how_to_use/step4/step4-3.png';
import ja_step4_4 from '../../assets/images/ja/how_to_use/step4/step4-4.png';
import ja_step4_5 from '../../assets/images/ja/how_to_use/step4/step4-5.png';

import en_step4_1 from '../../assets/images/en/how_to_use/step4/step4-1.png';
import en_step4_2 from '../../assets/images/en/how_to_use/step4/step4-2.png';
import en_step4_3 from '../../assets/images/en/how_to_use/step4/step4-3.png';
import en_step4_4 from '../../assets/images/en/how_to_use/step4/step4-4.png';
import en_step4_5 from '../../assets/images/en/how_to_use/step4/step4-5.png';

import en_troubleshoot_1 from '../../assets/images/en/how_to_use/troubleshoot/img1.png';
import en_troubleshoot_2 from '../../assets/images/en/how_to_use/troubleshoot/img2.png';
import en_troubleshoot_3 from '../../assets/images/en/how_to_use/troubleshoot/img3.png';

import ja_troubleshoot_1 from '../../assets/images/ja/how_to_use/troubleshoot/img1.png';
import ja_troubleshoot_2 from '../../assets/images/ja/how_to_use/troubleshoot/img2.png';
import ja_troubleshoot_3 from '../../assets/images/ja/how_to_use/troubleshoot/img3.png';

export const pictures = {
  en: {
    step1Imgs: {
      one: en_step1_1,
      two: en_step1_2,
      three: en_step1_3,
    },
    step2Imgs: {
      one: en_step2_1,
      two: en_step2_2,
    },
    step3Imgs: {
      one: en_step3_1,
      two: en_step3_2,
      three: en_step3_3,
      four: en_step3_4,
    },
    step4Imgs: {
      one: en_step4_1,
      two: en_step4_2,
      three: en_step4_3,
      four: en_step4_4,
      five: en_step4_5,
    },
    troubleshootImgs: {
      one: en_troubleshoot_1,
      two: en_troubleshoot_2,
      three: en_troubleshoot_3,
    },
  },
  ja: {
    step1Imgs: {
      one: ja_step1_1,
      two: ja_step1_2,
      three: ja_step1_3,
    },
    step2Imgs: {
      one: ja_step2_1,
      two: ja_step2_2,
    },
    step3Imgs: {
      one: ja_step3_1,
      two: ja_step3_2,
      three: ja_step3_3,
      four: ja_step3_4,
    },
    step4Imgs: {
      one: ja_step4_1,
      two: ja_step4_2,
      three: ja_step4_3,
      four: ja_step4_4,
      five: ja_step4_5,
    },
    troubleshootImgs: {
      one: ja_troubleshoot_1,
      two: ja_troubleshoot_2,
      three: ja_troubleshoot_3,
    },
  },
  returnToText: 'ホームへもどる',
};
