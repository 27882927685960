type Props = {
  [key: string]: string | number;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="43"
      height="40"
      viewBox="0 0 43 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.33398 33.3333H33.6395V30H9.33398V33.3333ZM33.6395 15H26.6951V5H16.2784V15H9.33398L21.4868 26.6667L33.6395 15Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
