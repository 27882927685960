import React from 'react';
import words from '../../constants/words';
import styled from '@emotion/styled';

const Container = styled.div`
  /* upload */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 40px;
  gap: 16px;

  width: 100%;
  min-height: 40px;

  /* white */
  background: #ffffff;
  /* lightgray */
  border-top: 1px solid #f8f8f8;
  /* shadow */
  box-shadow: 0px 2px 6px rgba(39, 128, 255, 0.05);

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  position: fixed;
  top: 54px;
  left: 0px;
  width: 100vw;
  text-align: left;

  @media only screen and (max-width: 600px) {
    top: 38px;
    padding: 8px 8px;
  }
`;
const Text = styled.span`
  /* 利用規約 */

  width: 100%;
  height: 24px;

  /* bold */
  font-family: 'BIZ UDPGothic';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.1em;

  /* black */
  color: #525664;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export type headerProps = {
  closeModal: (event: any) => void;
  menuItem: string;
  style?: React.CSSProperties;
};

const Component = ({
  menuItem = '',
  style,
}: headerProps): React.ReactElement => {
  const ItemMenus = {
    termsOfUse: words.termsOfUse,
    privacyPolicy: words.privacyPolicy,
    howToUse: words.howToUseGuide,
    company: words.company,
  };

  return (
    <Container style={style}>
      <Text>{ItemMenus[menuItem]}</Text>
    </Container>
  );
};

export default Component;
