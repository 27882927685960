import { Button, Grid, IconButton, Link, Modal } from '@mui/material';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import SignatureCanvas from 'react-signature-canvas';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DownloadIcon from '@mui/icons-material/DownloadForOffline';
import PDF from '../PDF';

export type AgreementModalProps = {
  open: boolean;
  handleClose: (event: any) => void;
  isError: boolean;
  userName: string;
  setUserName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearSignature: (event: any) => void;
  handleSaveDownload: (event: any) => void;
  setSigPad: (ref: any) => void;
  imageSigData: string;
  disablePdfButton: boolean;
};

const Component = ({
  open = false,
  handleClose = () => {},
  isError = false,
  userName = '',
  setUserName = () => {},
  handleClearSignature = () => {},
  handleSaveDownload = () => {},
  setSigPad = () => {},
  imageSigData = '',
  disablePdfButton = false,
}: AgreementModalProps): React.ReactElement => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title">
      <Grid
        container
        spacing={2}
        className="grid-container"
        id="grid-container">
        <Grid item xs={12}>
          <IconButton
            className="icon-btn"
            onClick={handleClose}
            aria-label="delete">
            <CancelIcon
              sx={{
                height: '46.345px',
                width: '46.345px',
                color: '#8B949F',
              }}
            />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <h2 id="child-modal-title">Terms and Conditions Agreement</h2>
        </Grid>
        <Grid className="agreement-body" id="agreement-body" item xs={12}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            euismod posuere dui pellentesque viverra. Donec pharetra, mi vitae
            blandit gravida. John Doe agrees... Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Vestibulum euismod posuere dui
            pellentesque viverra. Donec pharetra, mi vitae blandit gravida.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            euismod posuere dui pellentesque viverra. Donec pharetra, mi vitae
            blandit gravida.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Vestibulum euismod posuere dui pellentesque viverra. Donec
            pharetra, mi vitae blandit gravida. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Vestibulum euismod posuere dui
            pellentesque viverra. Donec pharetra, mi vitae blandit gravida.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            euismod posuere dui pellentesque viverra. Donec pharetra, mi vitae
            blandit gravida.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Vestibulum euismod posuere dui pellentesque viverra. Donec
            pharetra, mi vitae blandit gravida.Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Vestibulum euismod posuere dui
            pellentesque viverra. Donec pharetra, mi vitae blandit gravida.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            euismod posuere dui pellentesque viverra. Donec pharetra, mi vitae
            blandit gravida.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Vestibulum euismod posuere dui pellentesque viverra. Donec
            pharetra, mi vitae blandit gravida. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Vestibulum euismod posuere dui
            pellentesque viverra. Donec pharetra, mi vitae blandit gravida.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            euismod posuere dui pellentesque viverra. Donec pharetra, mi vitae
            blandit gravida.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Vestibulum euismod posuere dui pellentesque viverra. Donec
            pharetra, mi vitae blandit gravida.Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Vestibulum euismod posuere dui
            pellentesque viverra. Donec pharetra, mi vitae blandit gravida.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            euismod posuere dui pellentesque viverra. Donec pharetra, mi vitae
            blandit gravida. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Vestibulum euismod posuere dui pellentesque viverra. Donec
            pharetra, mi vitae blandit gravida.Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Vestibulum euismod posuere dui
            pellentesque viverra. Donec pharetra, mi vitae blandit gravida.
          </p>
        </Grid>
        <Grid className="signature-form" xs={12} item>
          <Grid item xs={12}>
            <TextField
              className="signature-form-textfield"
              fullWidth
              size="small"
              error={isError}
              id="outlined-controlled"
              placeholder="Enter Name"
              value={userName}
              onChange={setUserName}
            />
          </Grid>
          <Grid item xs={12}>
            <h2 className="signBelow" style={{ textAlign: 'center' }}>
              Sign below
            </h2>
          </Grid>
          <Grid item xs={12}>
            <small
              style={{
                float: 'right',
                paddingRight: '10px',
              }}>
              <Link
                className="clearSig"
                component="button"
                variant="body2"
                underline="none"
                onClick={handleClearSignature}>
                Clear signature
              </Link>
            </small>
          </Grid>
          <Grid item xs={12}>
            <div className="signature-canvas" id="signature-canvas">
              <SignatureCanvas onEnd={handleSaveDownload} ref={setSigPad} />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className="download-pdf-container"
            id="download-pdf-container">
            <PDFDownloadLink
              document={<PDF userName={userName} imageData={imageSigData} />}
              fileName="terms_and_conditions_agreement.pdf"
              className="file_link_download">
              {({ url, loading }) =>
                url && !loading ? (
                  <Button
                    className="pdf-btn"
                    sx={{ mt: 2 }}
                    size="large"
                    startIcon={
                      <DownloadIcon sx={{ width: 'auto', height: '40px' }} />
                    }
                    variant="contained"
                    disabled={disablePdfButton}>
                    Download as PDF
                  </Button>
                ) : (
                  <Button
                    className="pdf-btn"
                    sx={{ mt: 2 }}
                    size="large"
                    startIcon={
                      <DownloadIcon sx={{ width: 'auto', height: '40px' }} />
                    }
                    variant="contained"
                    disabled={true}>
                    Download as PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default Component;
