import React from 'react';
import words from '../../constants/words';
import styled from '@emotion/styled';

const NumberedItemRow = styled(`div`)`
  display: flex;
`;

const Number = styled(`span`)`
  padding-right: 5px;
`;

const NumberedItem = styled(`div`)`
  white-space: pre-line;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Component = (): React.ReactElement => {
  return (
    <Container>
      <div className="terms" id="terms">
        <li className="title">{words.serviceDescription}</li>
        <p className="terms-sub">{words.serviceDescriptionContent}</p>
        <li className="title">{words.feesDescription}</li>
        <p className="terms-sub">{words.feesDescriptionContent}</p>
        <li className="title">{words.scopeOfLicense}</li>
        <p className="terms-sub">
          <NumberedItemRow>
            <Number>(1)</Number>
            <NumberedItem>{words.scopeOfLicenseContent}</NumberedItem>
          </NumberedItemRow>
          <NumberedItemRow>
            <Number>(2)</Number>
            <NumberedItem>{words.scopeOfLicenseContentTwo}</NumberedItem>
          </NumberedItemRow>
        </p>
        <li className="title">{words.noNeedForRegistration}</li>
        <p className="terms-sub">{words.noNeedForRegistrationContent}</p>
        <li className="title">{words.prohibitedActions}</li>
        <p className="terms-sub">
          {'(1)'} {words.prohibitedActionsContent}
        </p>
        <p className="subTitle sub-words">
          {words.prohibitedActionsContentTwo}
        </p>
        <p className="subTitle sub-words">
          {words.prohibitedActionsContentThree}
        </p>
        <p className="terms-sub">
          {'(2)'} {words.prohibitedActionsContentFour}
        </p>
        <p className="subTitle sub-words">
          {words.prohibitedActionsContentFive}
        </p>
        <p className="terms-sub">
          {'(3)'} {words.prohibitedActionsContentSix}
        </p>
        <p className="subTitle sub-words">
          {words.prohibitedActionsContentSeven}
        </p>
        <li className="title">{words.authorityToCeaseUse}</li>
        <p className="terms-sub">{words.authorityToCeaseUseContent}</p>
        <li className="title">{words.disclaimer}</li>
        <p className="numbered first terms-sub">{words.disclaimerContent}</p>
        <p className="numbered terms-sub">{words.disclaimerContentTwo}</p>
        <p className="numbered terms-sub">{words.disclaimerContentThree}</p>
        <p className="numbered terms-sub">{words.disclaimerContentFour}</p>
        <li className="title">{words.jurisdiction}</li>
        <p className="terms-sub">{words.jurisdictionContent}</p>
        <li className="title">{words.revision}</li>
        <p className="terms-sub">{words.revisionContent}</p>
        <p className="established">{words.established}</p>
      </div>
    </Container>
  );
};

export default Component;
