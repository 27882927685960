import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';

const UnprotectedRoutes = () => (
  <Routes>
    <Route path="/*" element={<App />} />
  </Routes>
);

const RootNavigator = (): React.ReactElement => {
  return (
    <React.Suspense fallback={<div>loading routes...</div>}>
      <BrowserRouter>
        <UnprotectedRoutes />
      </BrowserRouter>
    </React.Suspense>
  );
};

export default RootNavigator;
