import { StepsWrapper } from './elements';
import words from '../../constants/words';
import { PlayIcon } from '../Icons';

const Steps = () => {
  return (
    <StepsWrapper>
      <div className="step_box1">
        <h1>
          step.1
          <span>{words.steps.step1.title}</span>
          <span>{words.steps.step1.subTitle}</span>
        </h1>
        <PlayIcon />
      </div>
      <div className="step_box2">
        <h1>
          step.2
          <span>{words.steps.step2.title}</span>
          <span>{words.steps.step2.subTitle}</span>
        </h1>
        <PlayIcon />
      </div>
      <div className="step_box3">
        <h1>
          step.3
          <span>{words.steps.step3.title}</span>
        </h1>
      </div>
    </StepsWrapper>
  );
};

export default Steps;
