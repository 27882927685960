type Props = {
  [key: string]: string | number;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="97"
      height="97"
      viewBox="0 0 97 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M48.5664 8.5C26.4864 8.5 8.56641 26.42 8.56641 48.5C8.56641 70.58 26.4864 88.5 48.5664 88.5C70.6464 88.5 88.5664 70.58 88.5664 48.5C88.5664 26.42 70.6464 8.5 48.5664 8.5ZM52.5664 68.5H44.5664V60.5H52.5664V68.5ZM52.5664 52.5H44.5664V28.5H52.5664V52.5Z"
        fill="#036EB8"
      />
    </svg>
  );
};

export default Component;
