import { useEffect } from 'react';
import { message } from 'antd';

/**
 * alerting errors now because we have no way of knowing the error messages when the app is in a hosted environment.
 * enable when in development.
 * disable if in production.
 * discuss with team when we want to enable in staging.
 */
// const SHOULD_ALERT_ERRORS = process.env.NODE_ENV !== 'production';
const SHOULD_ALERT_ERRORS = false;

export const useErrorLogs = () => {
  useEffect(() => {
    if (SHOULD_ALERT_ERRORS) {
      return window.addEventListener('error', (event) => {
        message.error(`error: ${event.message}`);
      });
    }
  }, []);

  useEffect(() => {
    if (SHOULD_ALERT_ERRORS) {
      return window.addEventListener(
        'unhandledrejection',
        (event: PromiseRejectionEvent) => {
          message.error(`unhandledrejection: ${event.reason.message}`);
        },
      );
    }
  }, []);
};
