import { styled } from '@mui/material';
import { BREAKPOINTS } from '../../constants/breakpoints';

export const PrivacyPolicyWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  color: #525664;
  font-family: 'BIZ UDPGothic';
  padding-bottom: 160px;
  @media screen and (max-width: 550px) {
    ul.sub-lists {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 600px) {
    padding-bottom: 104px;
  }
`;

export const Wrapper = styled('div')`
  padding: 0px;
  margin-right: 0px;
`;
export const UnorderedListWrapper = styled('ul')`
  margin: 16px 10px;

  @media screen and (max-width: 550px) {
    margin: 16px 0px;
    padding-left: 25px;
  }

  @media screen and (max-width: 768px) {
    li {
      font-size: 14px;
    }
  }
`;

export const AnchorWrapper = styled('a')`
  color: inherit;
`;

export const FooterWrapper = styled('div')`
  font-family: 'BIZ UDPGothic';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.5px;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 80px 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 16px;

  background: #fff;
  height: 80px;
  color: #525664;

  .footer_navs {
    font-size: 14px;
    line-height: normal;
    letter-spacing: unset;
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 64px;

    li {
      position: relative;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #000;
        right: -32px;
      }

      &:last-child::after {
        display: none;
      }
    }

    li.active {
      text-decoration: underline;
    }

    li.how_to_use {
      font-size: 16px;
      width: 150px;
      height: 150px;
      position: absolute;
      left: -170px;
      top: -110px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      border: 1px solid #525664;
      box-shadow: 0px 2px 6px 0px rgba(39, 128, 255, 0.05);

      &::after {
        display: none;
      }
    }
  }

  @media screen and (max-width: 810px) {
    .footer_navs {
      gap: 20px;

      li {
        &::after {
          right: -12px;
        }
      }
    }
  }

  @media screen and (max-width: 735px) {
    .footer_navs {
      gap: 20px;

      li {
        &::after {
          right: -12px;
        }
      }
      li.how_to_use {
        font-size: 14px;
        width: 120px;
        height: 120px;
        left: -125px;
        top: -81px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 16px 8px;

    .footer_navs {
      gap: 48px;

      li {
        font-size: 10px;

        &::after {
          right: -22px;
        }
      }
      li.how_to_use {
        display: none;
      }
    }
  }
`;

export const ActionsWrapper = styled('div')`
  flex: 1;

  display: flex;
  @media only screen and (min-width: ${BREAKPOINTS.md}px) {
    justify-content: start;
  }
  width: 100%;

  color: #fff;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  flex-direction: row;

  .active {
    text-decoration: underline;
  }

  div {
    cursor: pointer;
  }

  @media only screen and (min-width: ${BREAKPOINTS.md}px) {
    flex-direction: row;
    max-width: 390px;
  }
`;

export const ActionsSubWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  div {
    margin-bottom: 16px;
  }
  @media only screen and (min-width: ${BREAKPOINTS.md}px) {
    flex-direction: row;
    flex: none;
    justify-content: start;
    div {
      margin-bottom: 25px;
      margin-right: 32px;
    }
  }
`;

export const CopyrightLabel = styled('div')`
  font-family: 'BIZ UDPGothic';
  margin-top: 4px;
  width: 100%;
  text-align: center;

  color: #525664;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
