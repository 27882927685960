function audioTimerLoop(
  callback: (currentTime: number) => void,
  frequency: number,
): () => void {
  // AudioContext time parameters are in seconds
  const freq: number = frequency / 1000;

  const aCtx: AudioContext = new AudioContext();
  // Chrome needs our oscillator node to be attached to the destination
  // So we create a silent Gain Node
  const silence: GainNode = aCtx.createGain();
  silence.gain.value = 0;
  silence.connect(aCtx.destination);

  let stopped = false;
  let osc: OscillatorNode;

  const onOSCend = (): void => {
    osc = aCtx.createOscillator();
    osc.onended = onOSCend;
    osc.connect(silence);
    osc.start(0);
    osc.stop(aCtx.currentTime + freq);
    callback(aCtx.currentTime);
    if (stopped) {
      osc.onended = () => {
        return;
      };
    }
  };

  onOSCend();

  // return a function to stop our loop
  return (): void => {
    stopped = true;
  };
}

export default audioTimerLoop;
