const words = {
  eye: 'Eye',
  face: 'Face',
  black: 'Black',
  dark_skin: 'Dark Skin',
  blur: 'Blur',
  gray: 'Gray',
  mosaic: 'Mosaic',
  thin: 'Small',
  normal: 'Medium',
  thick: 'Large',
  uploadLabel: 'Upload Video',
  uploadLimit: '(Max 50MB）',
  recordAVideo: 'Record Video',
  reuseVideo: 'Reuse last video',
  signAgreementForm: 'Sign agreement form',
  successLabel: 'Success',
  successText: 'Your video is downloaded',
  targetBtn: 'Target',
  typeBtn: 'Type',
  sizeBtn: 'Size',
  infoLabel: 'Info',
  infoText: 'Select the cover setting and press the play button.',
  compile: 'Play Video',
  downloadVideo: 'Download video',
  compiling: 'Playing Video...',
  playVideo: 'Select masking settings then press play button',
  dialogVideo:
    'You will return to the home screen. The video you recorded will be deleted.',
  dialogVideoTwo: 'Are you sure you want to leave?',
  cancel: 'Cancel',
  okay: 'Okay',
  warning: 'Warning',
  howToUseGuide: 'How to use Mosaic Direct',
  howToUse: 'How to Use',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  company: 'Company',
  serviceDescription: '1. Service Description',
  serviceDescriptionContent:
    'This site provides a service that allows users to apply mosaic processing to photos and images online.',
  feesDescription: '2. Fees',
  feesDescriptionContent:
    'This site is completely free to use for all content.',
  scopeOfLicense: '3. Scope of License',
  scopeOfLicenseContent:
    'Commercial and Non-commercial Use\nThis site can be freely used for both commercial and non-commercial purposes, regardless of whether it is paid or free. This includes use in websites, banners, printed materials, flyers, advertisements, signs, displays, etc.',
  scopeOfLicenseContentTwo:
    'Restrictions on Use\nThe use of image materials from this site in content that violates laws, public order, or good morals is prohibited.',
  noNeedForRegistration: '4. No Need For Registration',
  noNeedForRegistrationContent:
    'The use of this site does not require registration, reporting, or any other procedures.',
  prohibitedActions: '5. Prohibited Actions (Use of Image Materials)',
  prohibitedActionsContent: 'Redistribution Prohibited',
  prohibitedActionsContentTwo:
    '① Redistributing image materials from this site as materials is prohibited.',
  prohibitedActionsContentThree:
    '② Modifying and redistributing image materials from this site is also prohibited.',
  prohibitedActionsContentFour: 'Prohibition on System Integration',
  prohibitedActionsContentFive:
    'Using image materials from this site as part of templates or integrating them into systems is prohibited. The use of these materials on image processing sites or systems competing with this site is also prohibited.',
  prohibitedActionsContentSix:
    'Prohibition on Trademark and Design Registration',
  prohibitedActionsContentSeven:
    'Registering images or logos created on this site as trademarks or designs is prohibited.',
  authorityToCeaseUse: '6. Authority to Cease Use of Image Materials',
  authorityToCeaseUseContent:
    'If the use of image materials from this site is deemed inappropriate, the site has the authority to prohibit the user from using the materials currently and in the future. Users who are requested to cease use must promptly delete the images in use.',
  disclaimer: '7. Disclaimer',
  disclaimerContent:
    '(1) This site is not involved in and assumes no responsibility for the users PC usage environment.',
  disclaimerContentTwo:
    '(2) This site bears no responsibility for any damages or compensation arising from the use of this site.',
  disclaimerContentThree:
    '(3) Copyrights for photos and images used on this site must be managed and used at the users own responsibility.',
  disclaimerContentFour:
    '(4) This site has no obligation to provide support for technical questions regarding the use of the site.',
  jurisdiction: '8. Jurisdiction',
  jurisdictionContent:
    'In the event of a dispute between this site and the user, the court in the jurisdiction where the site operator is located will be the agreed exclusive court of first instance.',
  revision: '9. Revision of Terms',
  revisionContent:
    'This site reserves the right to revise these terms at any time. When intending to revise the terms, the site will notify users on the site as needed.',
  established: 'Established on March 1, 2024',
  aboutTheProtectionOfPersonalInformation:
    'About the Protection of Personal Information',
  aboutTheProtectionOfPersonalInformationContent:
    'ORGO, Inc. (hereinafter referred to as "the Company") is acutely aware of the importance of protecting personal information in todays society. We strive daily to ensure a flawless internal system to avoid betraying the trust placed in us by our customers, complying with related laws, regulations, guidelines, etc. Under this understanding, we have established the following privacy policy (basic policy regarding the protection of personal information). In this policy, personal information refers to information regarding living individuals that can identify specific individuals by name or other descriptions contained therein (including information that can be easily compared with other information, thereby identifying specific individuals), specifically, information about customers (those who have purchased our products, used our services, or similar individuals).',
  introduction: 'Introduction',
  introductionContent:
    'The Company strongly recognizes the importance of protecting personal information and pays close attention daily to the handling of personal information by all its employees. We are committed to complying with laws and regulations related to the protection of personal information, other relevant laws, ordinances, various guidelines, etc. (hereinafter referred to as "laws and regulations, etc."), and to building a system that adheres to them. Furthermore, as part of the Companys internal rules, we have established personal information handling procedures and various manuals, ensuring that all employees are keenly aware of the importance of protecting personal information and carry out their duties accordingly. The Company will handle personal information as follows.',
  purposeOfUse: 'Purpose of Use',
  personalInfoFF:
    'Customer personal information will be used for the following purposes:',
  personalInfoPurposes: [
    'Confirmation of the individual, contract content, billing of service fees, notifications related to services (notifications of changes in service fees and service provision conditions, notifications of suspension, discontinuation, contract termination, changes in terms and conditions, etc.), and other matters related to the provision of Company services.',
    'Distribution of advertisements, delivery of service-related guidance through various media such as telephone, email, postal mail, the internet, and conducting surveys.',
    `Improvement of the services provided by the Company and user management of the Company's services, and to prevent the use of services in violation of service terms.`,
    'To confirm requests and inquiries from customers, to improve the quality of responses and service improvement by checking response history.',
  ],
  browsingInfoFF:
    'The browsing and purchase history collected will be used for the following purposes:',
  browsingInfoPurposes: [
    'For guiding services and events of the Company and its partners.',
    `For displaying advertisements related to the Company's services.`,
    `For investigating the usage of the Company's services, improving`,
  ],
  purposeOfUserPersonalInformation: 'Purpose of Use of Personal Information',
  purposeOfUseContent:
    'The personal information held by the Company will be handled within the scope of the purposes of use outlined below. \n(1) Handling of Personal Information of Customers',
  aquisitionUse: 'Acquisition, Use, and Provision',
  aquisitionUseContent:
    'For all personal information handled in the Companys business and that of its employees, appropriate acquisition, use, and provision are carried out, and personal information is not handled beyond the necessary scope to achieve the specified purposes of use. Appropriate measures are taken to prevent the use of personal information beyond its intended purpose. \nShould the handling of personal information exceed the intended purposes, prior consent will be obtained from the person.',
  safetyManagementMeasures: 'Safety Management Measures',
  safetyManagementMeasuresContent:
    'The Company implements safety management measures for personal information protection through the establishment of a management system, education and awareness activities for employees, management of entry and access to areas where personal information is handled, introduction of antivirus software, etc. Personal information that has exceeded the prescribed retention period is disposed of or deleted in an appropriate manner. \nTo ensure that these safety management measures are properly implemented, the Company obtains certification for information security from a third-party organization (ISMS) and continuously maintains and improves it.',
  provisionToThirdParties: 'Provision and Entrustment to Third Parties',
  provisionToThirdPartiesContent:
    'The Company will not disclose or provide personal information to third parties without the consent of the individual, except in cases necessary for achieving the purposes of use, when entrusting to a subcontractor who has signed a confidentiality agreement (provided that they sufficiently meet our personal information protection standards), when necessary to protect human life, body, or property and it is difficult to obtain the individuals consent, or in other cases required by law.',
  responseToIncidents: 'Response to Incidents',
  responseToIncidentsContent:
    'In the unlikely event of a personal information leak or other such incidents, the Company will promptly disclose and notify the incident, endeavor to conduct thorough fact-finding and cause analysis, and work to minimize damage. Furthermore, the causes of the incident will be thoroughly analyzed to take appropriate measures to prevent recurrence.',
  handlingOfPersonalInformation:
    '(1) Handling of Customer Personal Information',
  collectionOfPersonalInformation: 'Collection of Personal Information',
  collectionOfPersonalInformationContent:
    'The Company may collect personal information within the necessary scope. The collected personal information will be collected through lawful and fair means within the scope necessary to achieve the purposes of use defined below, and information that may infringe on the rights and interests of individuals will not be collected.',
  purposeOfUseOfPersonalInfo:
    'The Company uses personal information within the necessary scope to achieve the purposes of use related to its business activities. Personal information will not be acquired beyond the scope of these purposes or used for any other purposes.',
  purposeOfUseOfPersonalInfoContent:
    '1. Confirmation of the individual, contract content, billing of service fees, notifications related to services (notifications of changes in service fees and service provision conditions, notifications of suspension, discontinuation, contract termination, changes in terms and conditions, etc.), and other matters related to the provision of Company services.',
  purposeOfUseOfPersonalInfoContentTwo:
    '2. Distribution of advertisements, delivery of service-related guidance through various media such as telephone, email, postal mail, the internet, and conducting surveys.',
  purposeOfUseOfPersonalInfoContentThree:
    '3. Improvement of the services provided by the Company and user management of the Companys services, and to prevent the use of services in violation of service terms.',
  purposeOfUseOfPersonalInfoContentFour:
    '4. To confirm requests and inquiries from customers, to improve the quality of responses and service improvement by checking response history.',
  purposeOfUseOfPersonalInfoContentFive:
    'The browsing and purchase history collected will be used for the following purposes:',
  purposeOfUseOfPersonalInfoContentSix:
    '1. For guiding services and events of the Company and its partners.',
  purposeOfUseOfPersonalInfoContentSeven:
    '2. For displaying advertisements related to the Companys services.',
  purposeOfUseOfPersonalInfoContentEight:
    '3. For investigating the usage of the Companys services, improving',
  outsourcingOfPersonalInfo: 'Outsourcing of Personal Information',
  outsourcingOfPersonalInfoContent:
    'When outsourcing parts of our services, such as information processing, we may provide personal information to our subcontractors. In such cases, we select subcontractors who have secured a sufficient level of personal information protection and conclude confidentiality agreements with them before entrusting them with any information.',
  thirdPartyProvision: 'Third-Party Provision of Personal Information',
  thirdPartyProvisionContent:
    'Except in the following cases, we will not disclose personal information to third parties without the consent of the individual:',
  thirdPartyProvisionContentTwo: 'With the individuals consent.',
  thirdPartyProvisionContentThree:
    'When it is necessary to protect the life, body, or property of a person, and it is difficult to obtain the individuals consent.',
  thirdPartyProvisionContentFour: 'When required by law.',
  insufficientInfo: 'Insufficient Information',
  insufficientInfoContent:
    'If the personal information provided is insufficient, we may not be able to offer our services.',
  requestForDisclosure:
    'Requests for Disclosure, etc., of Personal Information',
  requestForDisclosureContent:
    'The individual or their representative can request notification of the purposes for which their personal information is used, disclosure, correction, addition or deletion of content, suspension of use, erasure, and suspension of provision to third parties (hereafter referred to as "disclosure, etc.").',
  requestForDisclosureContentTwo:
    'Upon receiving a request for disclosure, etc., we will respond promptly in accordance with our companys regulations.',
  requestForDisclosureContentThree:
    'We will verify whether the requester is the individual in question or their representative before proceeding with the request.',
  requestForDisclosureContentFour:
    'Please contact us for how to make a request for disclosure, etc.',
  requestForDisclosureContentFive:
    'Disclosure may be withheld in the following cases, and we will promptly notify the individual of the reason for withholding disclosure:',
  requestForDisclosureContentSix:
    'When there is a risk of harming the life, body, property, or other rights and interests of the person or a third party.',
  requestForDisclosureContentSeven:
    'When there is a significant possibility of hindering the proper execution of our business.',
  requestForDisclosureContentEight:
    'When it would lead to a violation of other laws.',
  managementOfPersonalInfo: 'Management of Personal Information',
  managementOfPersonalInfoContent:
    'We take appropriate technical and organizational security measures to prevent unauthorized access to, or the loss, destruction, alteration, or leakage of personal information.',
  aboutCookies: 'About Cookies',
  aboutCookiesContent:
    'To improve the convenience of our website, we may store files known as "cookies" on your computer through your web browser. Information collected by cookies includes the date and time of your last site visit and the number of visits, which is used in accordance with our privacy policy to understand how our website is used. You may disable cookies in your web browser settings, but please consult the provider of your web browser for how to do so. Please note that disabling cookies may result in some functions of our website being unavailable.',
  retentionPeriod: 'Retention Period of Personal Information',
  retentionPeriodContent:
    'Personal information of users will be erased after the termination of the service contract and once the specified retention period has elapsed, except for statistical data retained in a manner that does not allow the identification of individuals and other data as prescribed by law.',
  inquiriesRegardingPersonalInfo: 'Inquiries Regarding Personal Information',
  inquiriesRegardingPersonalInfoContent: 'ORGO, Inc.',
  inquiriesRegardingPersonalInfoContentTwo: {
    label: 'URL:',
    description: 'https://orgo.co.jp/contact/',
  },
  other: 'Other',
  otherContent:
    'This privacy policy may be revised as necessary to improve its contents. The revised privacy policy will be promptly posted on this site after the revision.',
  fileSizeLimitError: 'File size should not exceed 50MB.',
  errorTitle: 'Error',
  error: {
    fileFormatError: 'file upload failed.',
  },
  steps: {
    step1: {
      title: 'Upload',
      subTitle: 'Video',
    },
    step2: {
      title: 'Select',
      subTitle: 'Mosaic Type',
    },
    step3: {
      title: 'Download',
    },
  },
  topBtmInfo: {
    text1:
      'Automatically recognize people in the video and mosaic their faces.',
    text2: 'Easily create videos with privacy in mind.',
  },
  howToUsePage: {
    step1: {
      title: '<span>step.1</span>Add Mosaic Direct to your home screen',
      images: [
        {
          description: `<p>Open Mosaic Direct in Safari (browser) from the link below: <a href="https://mosaicdirect.jp/" target="_blank">https://<span>mosaicdirect.jp/</span></a></p>`,
        },
        {
          description: `<p>Click the share icon at the top of Safari (browser) and select <mark class="highlight">[Add to Home Screen]</mark>.</p>`,
        },
        {
          description: `<p>Mosaic Direct has been added to your home screen. <br>Tap the added icon to launch it.</p>`,
        },
      ],
    },
    step2: {
      title: '<span>step.2</span>Record or upload a video',
      subTitle: `<mark class="highlight">Please be online when uploading for the first time.</mark><br />
      Once you have uploaded a video while online, you can use the app even when offline.`,
      images: [
        {
          description: `<p>If you have already filmed a video , tap <mark class="highlight">[Upload Video]</mark> and select it from your camera roll.</p>`,
        },
        {
          description: `<p>If you want to record a video , tap <mark class="highlight">[Record video]</mark>.
          The camera will start.</p>`,
        },
      ],
    },
    step3: {
      title: '<span>step.3</span>Set Cover Mode',
      images: [
        {
          description: `<p>Set the cover mode. <br />
          This setting can be changed later.</p>`,
        },
        {
          description: `<p><mark class="highlight">Coverage</mark><br />
          <mark class="highlight">: Eyes : </mark> Covers only the eye area.<br />
          <mark class="highlight">: Face : </mark> Covers the entire face.</p>
          `,
        },
        {
          description: `<p>You can choose from four <mark class="highlight">Types of mask: mosaic, blur, black, and skin color.</mark></p>`,
        },
        {
          description: `<p><mark class="highlight">Cover Size </mark><br />
          You can choose from three cover sizes: <mark class="highlight">small, medium, and large.</mark>.</p>`,
        },
      ],
    },
    step4: {
      title: '<span>step.4</span>View and download the video',
      images: [
        {
          description: `<p>Tap <mark class="highlight">[Play]</mark> to see the edited video. <br />
          You can also change the cover. After changing it , tap <mark class="highlight">[Play]</mark> again to see the edited video.
          </p>`,
        },
        {
          description: `<p>After checking, you can save the video.<br />
          Tap <mark class="highlight">[Download]</mark>. The video will be saved in the [File > Download] folder on your iPad.</p>`,
        },
        {
          description: `<p>If the above screen appears after tapping <mark class="highlight">[Download]</mark>, please do the following: <br />
          Tap "More...".</p>`,
        },
        {
          description: `<p>Tap <mark class="highlight">[Save Video]</mark>. You can view the saved video in the Photos app.</p>`,
        },
        {
          description: `<p>Tap <mark class="highlight">[Done]</mark> to return to the previous screen.</p>`,
        },
      ],
    },
    environment: {
      title: 'Recommended Environment',
      bullets: [
        {
          title: 'Operating System (OS)',
          list: [
            'iOS: Version 17 or later',
            'iPadOS: Version 17 or later',
            'macOs: macOS 13.0 (Ventura) or later',
            'Windows: Windows 10 or later',
          ],
        },
        {
          title: 'Browser',
          list: ['Google Chrome: Latest version', 'Safari: Latest version'],
        },
        {
          title: 'Hardware',
          list: [
            'RAM: 8GB or more',
            'Storage: At least 500MB of free space',
            'Chipset: M1 or later for Apple devices, or equivalent ARM-based or x86 processors for other devices',
            'Screen Resolution: 1280x720 pixels or higher, up to 1920 pixels wide',
            'Camera: 12MP or higher for better face detection accuracy',
          ],
        },
        {
          title: 'Others',
          list: ['Javascript: Enabled'],
        },
      ],
    },
    troubleshoot: {
      headerTitle: `Troubleshooting <br />
      -Q＆A-`,
      subTitle: 'Setting method',
      question: `If you try to upload a video that is less than 50MB, you will receive an error message saying 
                <mark class="red">"Please upload a file less than 50MB"</mark> and will not be able to upload.`,
      answer: `If the default video recording format setting on your iPhone or iPad is set to <mark class="red">"High Efficiency",</mark>
                the file size will increase when you upload (by about 15MB), which will result in an error on the app.</br>
                This can be resolved by setting the recording format to <mark class="highlight">"Compatibility Priority"</mark> beforehand.`,
      images: [
        {
          description:
            '<p>Open [Camera] from the Settings app on the home screen and select [Format]</p>',
        },
        {
          description:
            '<p>If [High Efficiency] is checked, select [Compatibility Priority]</p>',
        },
        {
          description: `<p>Make sure that [Compatibility Priority] is checked, close the Settings app,
and then try shooting and uploading again.</p>`,
        },
      ],
    },
    returnToText: 'Return to Home',
  },
};

export default words;
