type Props = {
  [key: string]: string | number;
};

export const BookIcon = (props: Props): React.ReactElement => {
  return (
    <svg
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M34.7143 0.902345H25.5214C23.5487 0.902345 21.6201 1.46886 19.9607 2.53761L18 3.7952L16.0393 2.53761C14.3816 1.46907 12.4508 0.901291 10.4786 0.902345H1.28571C0.574554 0.902345 0 1.4769 0 2.18806V25.0095C0 25.7206 0.574554 26.2952 1.28571 26.2952H10.4786C12.4513 26.2952 14.3799 26.8617 16.0393 27.9305L17.8232 29.0796C17.8754 29.1117 17.9357 29.1318 17.996 29.1318C18.0562 29.1318 18.1165 29.1157 18.1688 29.0796L19.9527 27.9305C21.6161 26.8617 23.5487 26.2952 25.5214 26.2952H34.7143C35.4254 26.2952 36 25.7206 36 25.0095V2.18806C36 1.4769 35.4254 0.902345 34.7143 0.902345ZM10.4786 23.4023H2.89286V3.7952H10.4786C11.9009 3.7952 13.283 4.20101 14.4763 4.96842L16.4371 6.22601L16.7143 6.40681V24.9693C14.8018 23.9407 12.6643 23.4023 10.4786 23.4023ZM33.1071 23.4023H25.5214C23.3357 23.4023 21.1982 23.9407 19.2857 24.9693V6.40681L19.5629 6.22601L21.5237 4.96842C22.717 4.20101 24.0991 3.7952 25.5214 3.7952H33.1071V23.4023ZM13.3754 8.93806H5.91027C5.75357 8.93806 5.625 9.07467 5.625 9.2394V11.0474C5.625 11.2122 5.75357 11.3488 5.91027 11.3488H13.3714C13.5281 11.3488 13.6567 11.2122 13.6567 11.0474V9.2394C13.6607 9.07467 13.5321 8.93806 13.3754 8.93806ZM22.3393 9.2394V11.0474C22.3393 11.2122 22.4679 11.3488 22.6246 11.3488H30.0857C30.2424 11.3488 30.371 11.2122 30.371 11.0474V9.2394C30.371 9.07467 30.2424 8.93806 30.0857 8.93806H22.6246C22.4679 8.93806 22.3393 9.07467 22.3393 9.2394ZM13.3754 14.5631H5.91027C5.75357 14.5631 5.625 14.6997 5.625 14.8644V16.6724C5.625 16.8372 5.75357 16.9738 5.91027 16.9738H13.3714C13.5281 16.9738 13.6567 16.8372 13.6567 16.6724V14.8644C13.6607 14.6997 13.5321 14.5631 13.3754 14.5631ZM30.0897 14.5631H22.6246C22.4679 14.5631 22.3393 14.6997 22.3393 14.8644V16.6724C22.3393 16.8372 22.4679 16.9738 22.6246 16.9738H30.0857C30.2424 16.9738 30.371 16.8372 30.371 16.6724V14.8644C30.375 14.6997 30.2464 14.5631 30.0897 14.5631Z"
        fill="#525664"
      />
    </svg>
  );
};
