type Props = {
  [key: string]: string | number;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_2471_1048)">
        <path
          d="M12.2143 26.5323H7.55357V21.8716C7.55357 21.6948 7.40893 21.5502 7.23214 21.5502H5.30357C5.12679 21.5502 4.98214 21.6948 4.98214 21.8716V26.5323H0.321429C0.144643 26.5323 0 26.6769 0 26.8537V28.7823C0 28.9591 0.144643 29.1037 0.321429 29.1037H4.98214V33.7644C4.98214 33.9412 5.12679 34.0859 5.30357 34.0859H7.23214C7.40893 34.0859 7.55357 33.9412 7.55357 33.7644V29.1037H12.2143C12.3911 29.1037 12.5357 28.9591 12.5357 28.7823V26.8537C12.5357 26.6769 12.3911 26.5323 12.2143 26.5323Z"
          fill="white"
        />
        <path
          d="M34.0714 9.58411L28.9286 12.5453V6.43813C28.9286 5.01982 27.7754 3.8667 26.3571 3.8667H2.57143C1.15313 3.8667 0 5.01982 0 6.43813V20.581H2.89286V6.75956H26.0357V29.2596H15.4286V32.1524H26.3571C27.7754 32.1524 28.9286 30.9993 28.9286 29.581V23.4738L34.0714 26.435C34.9272 26.9292 36 26.3104 36 25.3261V10.6971C36 9.70866 34.9272 9.08991 34.0714 9.58411ZM33.1071 22.5497L28.9286 20.1471V15.8761L33.1071 13.4694V22.5497Z"
          fill="white"
        />
        <path
          d="M10.2839 11.8878C10.4607 11.8878 10.6053 11.7431 10.6053 11.5663V9.63776C10.6053 9.46098 10.4607 9.31634 10.2839 9.31634H5.78388C5.6071 9.31634 5.46245 9.46098 5.46245 9.63776V11.5663C5.46245 11.7431 5.6071 11.8878 5.78388 11.8878H10.2839Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2471_1048">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Component;
