type Props = {
  [key: string]: string | number;
};

const Component = (props: Props): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      {...props}>
      <path
        d="M13.8887 8.33301V31.6663L32.9859 19.9997L13.8887 8.33301Z"
        fill="white"
      />
    </svg>
  );
};

export default Component;
