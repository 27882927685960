import { CircularProgress, Grid, styled } from '@mui/material';
import React from 'react';
import words from '../../constants/words';
import { Tips } from '../Icons';
import { getViewport } from '../../utils/viewport';
import { BREAKPOINTS } from '../../constants/breakpoints';

export type canvasProps = {
  canvasSizeWidth: number;
  canvasSizeHeight: number;
  activatePreview: boolean;
  showVideoInfo: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
  isLoading: boolean;
  hideLoadingIcon: boolean;
};

const LoadingContainer = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
`;

const StyledGridWrapper = styled('div')`
  width: 100%;
  div.tipsIcon {
    margin-top: 200px;
  }
  div.tipsIcon svg {
    width: 75px;
  }

  p.videoInfo {
    position: relative;
  }
`;

// NOTE: media queries here should be handled responsively. Please refactor if time allows
const StyledGrid = styled(Grid)<{
  isPreview: boolean;
}>`
  ${({ isPreview }) =>
    isPreview
      ? `
      max-height: calc(100svh - 66px - 137px);
      height: calc(100svh - 66px - 137px);
      @media only screen and (min-width: ${BREAKPOINTS.md}px) {
        max-height: calc(100svh - 80px - 137px);
        height: calc(100svh - 80px - 137px);
      }
  `
      : ``}
`;

const containerStyles: { [key: string]: React.CSSProperties } = {
  preview: {
    background: '#ffffff',
    // 97px = header height, 200px = footer height
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    paddingBottom: 50,
  },
  upload: {
    background: '#E9ECF3',
    height: 48,
  },
};

const Component = ({
  canvasSizeWidth = 0,
  canvasSizeHeight = 0,
  activatePreview = false,
  showVideoInfo = false,
  isLoading = false,
  hideLoadingIcon = false,
}: canvasProps): React.ReactElement => {
  const { viewPortWidth, viewPortHeight } = getViewport();
  const isHorizontal =
    (viewPortHeight > viewPortWidth && canvasSizeWidth > canvasSizeHeight) ||
    (canvasSizeHeight < canvasSizeWidth && viewPortHeight > viewPortWidth);

  return (
    <StyledGridWrapper>
      <StyledGrid
        isPreview={activatePreview}
        style={{
          overflow: 'hidden',
          width: '100%',
          display: activatePreview ? 'flex' : 'none',
          ...containerStyles[activatePreview ? 'preview' : 'upload'],
        }}
        item
        margin={0}
        xs={12}>
        {showVideoInfo && (
          <>
            <div className="tipsIcon">
              <Tips />
            </div>
            <p className="videoInfo">{words.infoText}</p>
          </>
        )}
        {isLoading && !hideLoadingIcon && activatePreview && (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
        <canvas
          id="imgCanvas"
          width={canvasSizeWidth}
          height={canvasSizeHeight}
          style={{
            ...(isHorizontal
              ? {
                  width: '100%',
                  height: 'auto',
                }
              : {
                  width: 'auto',
                  height: '100%',
                }),
            aspectRatio: 'auto',
          }}
        />
        <canvas
          id="canvas"
          width={canvasSizeWidth}
          height={canvasSizeHeight}
          style={{
            ...(isHorizontal
              ? {
                  width: '100%',
                  height: 'auto',
                }
              : {
                  width: 'auto',
                  height: '100%',
                }),
            aspectRatio: 'auto',
          }}
        />
      </StyledGrid>
    </StyledGridWrapper>
  );
};

export default Component;
