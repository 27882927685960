import { styled } from '@mui/material';

export const HowToUseWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;

  padding-bottom: 160px;

  .info_btn {
    font-family: 'BIZ UDPGothic';
    color: #525664;
    width: 183px;
    max-width: 100%;
    margin: auto;
    min-height: 40px;
    background: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 700;
  }

  @media screen and (max-width: 600px) {
    gap: 16px;
    padding-bottom: 96px;
  }
`;
