interface frameNumberArgInfo {
  framesCounts: number[];
  framesDurations: number[];
  timeScale: number;
}

/**
 * @param {Array} argInfo.framesCounts - list of frame counts grouped by duration
 * @param {Number} argInfo.framesCounts[0] - frames count for particular duration
 * @param {Number} argInfo.framesDurations[0] - frame duration, in time units
 * @param {Number} argInfo.timeScale - timebase for converting time units into seconds
 * @param {Number} timestampToFind - requested time whose index should be found, in seconds
 */
export const getTimestampIndex = (
  argInfo: frameNumberArgInfo,
  timestampToFind: number,
) => {
  // Convert user time to time units
  const findTime = timestampToFind * argInfo.timeScale;
  let k = 0;
  let time = 0;

  for (let i = 0; i < argInfo.framesCounts.length; i += 1) {
    for (let j = 0; j < argInfo.framesCounts[i]; j += 1) {
      if (time >= findTime) {
        return Math.max(1, k - 1);
      }
      time += argInfo.framesDurations[i];
      k += 1;
    }
  }

  return time >= findTime ? Math.max(0, k - 1) : k - 1;
};

export const restartVideo = async (): Promise<boolean> => {
  return new Promise((resolve) => {
    // NOTE: this has to exist in the DOM tree. Otherwise, this function does nothing.
    const video = document.getElementsByTagName('video')[0];
    if (!video) {
      resolve(false);
    }
    video.muted = false;
    video.pause();
    video.currentTime = 0;

    const intervalId = setInterval(() => {
      if (video.currentTime >= 0) {
        video.muted = false;
        video.pause();
        if (intervalId) {
          clearInterval(intervalId);
        }
        resolve(true);
      }
    }, 100);
  });
};

export const validateVideoFile = (file: File): Promise<boolean> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    const videoURL = URL.createObjectURL(file);
    video.src = videoURL;
    video.load();

    video.onerror = () => {
      resolve(false); // Resolve with false as the video is not valid
    };

    video.onloadeddata = () => {
      resolve(true); // Resolve with true as the video is valid
    };
  });
};
