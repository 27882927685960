import { Button, Grid, styled } from '@mui/material';
import React from 'react';
import { AlertModal } from '../Modals';
import { Popover } from 'antd';
import words from '../../constants/words';
import { Play, DownloadIcon } from '../../components/Icons';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { BREAKPOINTS } from '../../constants/breakpoints';

export type activePreviewProps = {
  closeSuccessAlert: (event: any) => void;
  downloadVideo: (event: any) => void;
  submitVideo: (event: any) => void;
  showAlert: boolean;
  videoUrl: string;
  contentTarget: JSX.Element;
  contentType: JSX.Element;
  contentMosaic: JSX.Element;
  faceType: string;
  faceTarget: string;
  mosaicSize: string;
  isLoading: boolean;
  isEdited: boolean;
  isProcessing: boolean;
  filename: string;
};

const ActionsContainer = styled(Grid)`
  border-top: 1px solid #8b949f;
  background-color: #fff;
  padding: 26px 12px 20px 12px;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  .Mui-disabled div {
    color: rgba(0, 0, 0, 0.26);
  }
  .MuiGrid-item {
    padding-top: 0;
  }
`;

const ButtonTitle = styled('div')`
  font-size: 24px;
  margin-bottom: 5px;

  @media only screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
    margin-bottom: -5px;
  }
`;

const ButtonSubtitle = styled('div')`
  color: var(--DS-black-2, #036eb8);
  text-align: center;
  font-family: MsGothic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  @media only screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 10px;
  }
`;

const FaceBtn = styled(Button)`
  display: inline-block;

  border-radius: 8px;
  border: 1px solid var(--ds-black-2, #036eb8);
  background: var(--ds-white, #fff);
  color: var(--ds-black-2, #036eb8);
  text-align: center;
  font-family: MsGothic;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: capitalize;
  max-width: 320px;
  width: 100%;
  height: 60px;

  @media only screen and (max-width: ${BREAKPOINTS.md}px) {
    &&,
    &&:hover,
    &&:focus {
      border-width: 1px;
      padding: 0;
    }
  }
`;

const ConfigureBtn = styled(Button)`
  background: #036eb8;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  margin-top: 25px;
  margin-bottom: 10px;
  height: 45px;
  position: relative;
  top: 0px;

  &:hover {
    background: #036eb8;
    color: #ffffff;
  }

  &:hover svg {
    position: relative;
    top: 0px;
  }

  &:hover div span {
    position: relative;
    top: 0px;
  }

  div span {
    font-size: 24px;
  }
  @media only screen and (max-width: ${BREAKPOINTS.md}px) {
    div span {
      font-size: 16px;
      margin: auto;
    }
  }
`;

const date = new Date();

const Component = ({
  closeSuccessAlert = () => {},
  downloadVideo = () => {},
  submitVideo = () => {},
  videoUrl = '',
  contentTarget = <></>,
  contentType = <></>,
  contentMosaic = <></>,
  showAlert = false,
  faceType = '',
  faceTarget = '',
  mosaicSize = '',
  isLoading = false,
  isEdited = false,
  isProcessing = true,
  filename = '',
}: activePreviewProps): React.ReactElement => {
  const downloadURL = (e: any) => {
    saveAs(
      videoUrl,
      `${filename + '-masked' + moment(date).format('YYYYMDDhmmss')}.mp4`,
    );
    downloadVideo(e);
  };

  return (
    <>
      <AlertModal
        alertPosition="bottom"
        closeSuccessAlert={closeSuccessAlert}
        showAlertError={showAlert}
        severity="success"
        alertTitle={words.successLabel}
        alertMessage={words.successText}
        backgroundColor="#D5F1E0"
        color="#000"
      />
      <ActionsContainer container spacing={2} className="actions-container">
        <Grid item xs={4} paddingTop={0}>
          <Popover
            className="pop-video-settings"
            content={contentTarget}
            trigger="click">
            <FaceBtn
              disabled={isLoading}
              id="face-size-btn"
              fullWidth
              size="large"
              variant="outlined"
              onClick={(e) => e.preventDefault()}>
              <ButtonTitle>{words.targetBtn}</ButtonTitle>
              <ButtonSubtitle>
                {faceTarget === 'eye' ? words.eye : words.face}
              </ButtonSubtitle>
            </FaceBtn>
          </Popover>
        </Grid>
        <Grid item xs={4}>
          <Popover content={contentType} trigger="click">
            <FaceBtn
              disabled={isLoading}
              id="color-size-btn"
              fullWidth
              size="large"
              variant="outlined"
              onClick={(e) => e.preventDefault()}>
              <ButtonTitle>{words.typeBtn}</ButtonTitle>
              <ButtonSubtitle>
                {faceType === 'black'
                  ? words.black
                  : faceType === 'blur'
                    ? words.blur
                    : faceType === 'dark_skin'
                      ? words.dark_skin
                      : words.mosaic}
              </ButtonSubtitle>
            </FaceBtn>
          </Popover>
        </Grid>
        <Grid item xs={4} paddingTop={0}>
          <Popover content={contentMosaic} trigger="click">
            <FaceBtn
              disabled={isLoading}
              id="mosaic-size-btn"
              fullWidth
              size="large"
              variant="outlined"
              onClick={(e) => e.preventDefault()}>
              <ButtonTitle>{words.sizeBtn}</ButtonTitle>
              <ButtonSubtitle>
                {mosaicSize === 'thin'
                  ? words.thin
                  : mosaicSize === 'normal'
                    ? words.normal
                    : words.thick}
              </ButtonSubtitle>
            </FaceBtn>
          </Popover>
        </Grid>
        <Grid item xs={12} padding={0}>
          {videoUrl !== '' && !isEdited && !isLoading ? (
            <ConfigureBtn
              className="configure-btn"
              aria-label="download-video"
              onClick={downloadURL}
              fullWidth
              size="large"
              variant="outlined">
              <div>
                <DownloadIcon /> <span>{words.downloadVideo}</span>
              </div>
            </ConfigureBtn>
          ) : (
            <>
              {!isLoading && isProcessing ? (
                <ConfigureBtn
                  className="configure-btn"
                  fullWidth
                  size="large"
                  variant="outlined"
                  onClick={submitVideo}>
                  <div>
                    <Play /> <span>{words.compile}</span>
                  </div>
                </ConfigureBtn>
              ) : (
                <ConfigureBtn
                  className="configure-onload-btn"
                  fullWidth
                  size="large"
                  variant="outlined"
                  onClick={(e) => e.preventDefault()}>
                  <div>
                    <Play /> <span>{words.compiling}</span>
                  </div>
                </ConfigureBtn>
              )}
            </>
          )}
        </Grid>
      </ActionsContainer>
    </>
  );
};

export default Component;
